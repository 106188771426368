// Actions
const SHOW_MODAL = "SHOW_MODAL";
const CLOSE_MODAL = "CLOSE_MODAL";
const START_LOADER = "START_LOADER";
const STOP_LOADER = "STOP_LOADER";
const SHOW_NOTIFBAR = "SHOW_NOTIFBAR";
const CLOSE_NOTIFBAR = "CLOSE_NOTIFBAR";
const SHOW_REQUEST_IN_QUEUE_PROMPT = "SHOW_REQUEST_IN_QUEUE_PROMPT";
const CLOSE_REQUEST_IN_QUEUE_PROMPT = "CLOSE_REQUEST_IN_QUEUE_PROMPT";
const PAGE_TITLE_CHANGE = "PAGE_TITLE_CHANGE";
const SET_SELECTED_PLAN = "SET_SELECTED_PLAN";
const SHOW_REFERRAL = "SHOW_REFERRAL";
const CLOSE_REFERRAL = "CLOSE_REFERRAL";
const DEFAULT_CONTENT = "DEFAULT_CONTENT";
const SET_QUES_VALUE_TYPE = "SET_QUES_VALUE_TYPE";
const SET_INITAL_QUES = "SET_INITAL_QUES";
const SET_QUES_VALUE_COUNT = "SET_QUES_VALUE_COUNT";
const SET_LIFETIME_PLAN_HEADER = "SET_LIFETIME_PLAN_HEADER";
const SET_GENERATE_DIAGRAM = "SET_GENERATE_DIAGRAM";
const SET_MCQ_HOTS = "SET_MCQ_HOTS";

// Action Creators
export function showLifetimeFomo(payload) {
  return { type: SET_LIFETIME_PLAN_HEADER, payload: payload };
}

export function showModal() {
  return { type: SHOW_MODAL };
}

export function setQuesValueType(payload) {
  return { type: SET_QUES_VALUE_TYPE, payload: payload };
}

export function setQuesValueCount(payload) {
  return { type: SET_QUES_VALUE_COUNT, payload: payload };
}

export function setGenerateDiagram(payload) {
  return { type: SET_GENERATE_DIAGRAM, payload: payload };
}
export function setMcQLevelHots(payload) {
  return { type: SET_MCQ_HOTS, payload: payload };
}

export function setInitialQues() {
  return { type: SET_INITAL_QUES };
}

export function closeModal() {
  document.body.classList.remove("modal-open");
  return { type: CLOSE_MODAL };
}

export function showReferral(payload) {
  return { type: SHOW_REFERRAL, payload: payload };
}

export function closeReferral() {
  return { type: CLOSE_REFERRAL };
}

export function showNotificationBar() {
  return { type: SHOW_NOTIFBAR };
}

export function closeNotificationBar() {
  return { type: CLOSE_NOTIFBAR };
}

export function showRequestInQueuePrompt() {
  return { type: SHOW_REQUEST_IN_QUEUE_PROMPT };
}

export function closeRequestInQueuePrompt() {
  return { type: CLOSE_REQUEST_IN_QUEUE_PROMPT };
}

export function startLoader() {
  return { type: START_LOADER };
}

export function stopLoader() {
  return { type: STOP_LOADER };
}

export function changePageTitle(title) {
  document.title = "Prep AI | " + title;
  return { type: PAGE_TITLE_CHANGE, title };
}

export function setSelectedPlan(payload) {
  return { type: SET_SELECTED_PLAN, payload };
}

export function showDefaultContent(payload) {
  return { type: DEFAULT_CONTENT, payload };
}

const initialState = {
  isBlooms: false,
  modelShow: false,
  showPopUp: false,
  referContent: null,
  notificationBarShow: false,
  requestInQueuePromptShow: false,
  refreshing: true,
  page_title: "Dashboard",
  UserName: "",
  plan: {},
  quesTypes: [],
  quesCount: null,
  showLifetimeFomo: false,
  mcq_level: 3,
  with_diagram: 0,
};

// Reducer
export default function reducer(
  state = initialState,
  { type, payload = null }
) {
  switch (type) {
    case SHOW_MODAL:
      return modalOpen(state);
    case CLOSE_MODAL:
      return modalClose(state);
    case SHOW_NOTIFBAR:
      return notificationBarOpen(state);
    case CLOSE_NOTIFBAR:
      return notificationBarClose(state);
    case SHOW_REQUEST_IN_QUEUE_PROMPT:
      return requestInQueuePromptOpen(state);
    case CLOSE_REQUEST_IN_QUEUE_PROMPT:
      return requestInQueuePromptClose(state);
    case START_LOADER:
      return loaderStart(state);
    case STOP_LOADER:
      return loaderStop(state);
    case PAGE_TITLE_CHANGE:
      return pageTitleChange(state, payload);
    case SET_SELECTED_PLAN:
      return setPlan(state, payload);
    case SHOW_REFERRAL:
      return showReferralPopup(state, payload);
    case CLOSE_REFERRAL:
      return closeReferralPopup(state);
    case DEFAULT_CONTENT:
      return addDefaultContent(state, payload);
    case SET_QUES_VALUE_TYPE:
      return setQuesType(state, payload);
    case SET_QUES_VALUE_COUNT:
      return setQuesCount(state, payload);
    case SET_INITAL_QUES:
      return initialQuesValue(state);
    case SET_LIFETIME_PLAN_HEADER:
      return setShowLifetimeFomo(state, payload);
    case SET_GENERATE_DIAGRAM:
      return setDiagram(state, payload);
    case SET_MCQ_HOTS:
      return setMcqHots(state, payload);
    default:
      return state;
  }
}

function setDiagram(state, payload) {
  return {
    ...state,
    with_diagram: payload,
  };
}

function setMcqHots(state, payload) {
  return {
    ...state,
    mcq_level: payload,
  };
}

function setShowLifetimeFomo(state, payload) {
  return {
    ...state,
    showLifetimeFomo: payload,
  };
}

function setQuesType(state, payload) {
  return {
    ...state,
    quesTypes: payload,
  };
}
function setQuesCount(state, payload) {
  return {
    ...state,
    quesCount: payload,
  };
}
function initialQuesValue(state) {
  return {
    ...state,
    quesTypes: [],
    quesCount: null,
    mcq_level: 3,
  };
}

function modalOpen(state) {
  return {
    ...state,
    modelShow: true,
  };
}

function showReferralPopup(state, payload) {
  return {
    ...state,
    showPopUp: true,
    referContent: payload,
  };
}

function closeReferralPopup(state) {
  return {
    ...state,
    showPopUp: false,
    referContent: null,
  };
}

function modalClose(state) {
  return {
    ...state,
    modelShow: false,
  };
}

function notificationBarOpen(state) {
  return {
    ...state,
    notificationBarShow: true,
  };
}

function notificationBarClose(state) {
  return {
    ...state,
    notificationBarShow: false,
  };
}

function requestInQueuePromptOpen(state) {
  return {
    ...state,
    requestInQueuePromptShow: true,
  };
}

function requestInQueuePromptClose(state) {
  return {
    ...state,
    requestInQueuePromptShow: false,
  };
}

function loaderStart(state) {
  return {
    ...state,
    refreshing: true,
  };
}
function loaderStop(state) {
  return {
    ...state,
    refreshing: false,
  };
}

function pageTitleChange(state, payload) {
  return {
    ...state,
    page_title: payload,
  };
}

function setPlan(state, payload) {
  return {
    ...state,
    plan: payload,
  };
}

function addDefaultContent(state, payload) {
  return {
    ...state,
    defaultContent: payload,
  };
}
