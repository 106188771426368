import React from "react";
import styles from "../../styles/demoResponsive.module.css";
import classNames from "classnames";

const CategoryBoxSubject = ({ state, is_new_pricing, handleQueTypeClick }) => {
  return (
    <div className="custom-nav-pills-column">
      <div
        className="nav flex-column nav-pills"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        <a
          className={classNames(styles["category-nav-link"], "nav-link active")}
          id="v-pills-easy-tab"
          data-toggle="pill"
          href="#v-pills-easy"
          role="tab"
          aria-controls="v-pills-easy"
          aria-selected="true"
          onClick={(e) => handleQueTypeClick(e, "easymcq", 1)}
        >
          1 Marks
          <span>{state.ques_count.easyCount}</span>
        </a>
        <a
          className={classNames(styles["category-nav-link"], "nav-link")}
          id="v-pills-medium-tab"
          data-toggle="pill"
          href="#v-pills-medium"
          role="tab"
          aria-controls="v-pills-medium"
          aria-selected="false"
          onClick={(e) => handleQueTypeClick(e, "mediummcq", 2)}
        >
          2 Marks
          {/* {is_new_pricing === 1 && (
          <img
            src="img/crown.jpg"
            alt="icon"
            width="30"
            height="30"
            style={{
              position: "absolute",
              marginTop: "-12px",
              marginLeft: "-7px",
            }}
          />
        )} */}
          <span>{state.ques_count.mediumCount}</span>
        </a>
        <a
          className={classNames(styles["category-nav-link"], "nav-link")}
          id="v-pills-hard-tab"
          data-toggle="pill"
          href="#v-pills-hard"
          role="tab"
          aria-controls="v-pills-hard"
          aria-selected="false"
          onClick={(e) => handleQueTypeClick(e, "hardmcq", 3)}
        >
          3 Marks
          {/* {is_new_pricing === 1 && (
          <img
            src="img/crown.jpg"
            alt="icon"
            width="30"
            height="30"
            style={{
              position: "absolute",
              marginTop: "-12px",
              marginLeft: "-7px",
            }}
          />
        )} */}
          <span>{state.ques_count.hardCount}</span>
        </a>
        <a
          className={classNames(styles["category-nav-link"], "nav-link")}
          id="v-pills-truefalse-tab"
          data-toggle="pill"
          href="#v-pills-truefalse"
          role="tab"
          aria-controls="v-pills-truefalse"
          aria-selected="false"
          onClick={(e) => handleQueTypeClick(e, "truefalse", 4)}
        >
          4 Marks
          {/* {is_new_pricing === 1 && (
          <img
            src="img/crown.jpg"
            alt="icon"
            width="30"
            height="30"
            style={{
              position: "absolute",
              marginTop: "-12px",
              marginLeft: "-7px",
            }}
          />
        )} */}
          <span>{state.ques_count.trueFalseCount}</span>
        </a>
        <a
          className={classNames(styles["category-nav-link"], "nav-link")}
          id="v-pills-Descriptive-tab"
          data-toggle="pill"
          href="#v-pills-Descriptive"
          role="tab"
          aria-controls="v-pills-Descriptive"
          aria-selected="false"
          onClick={(e) => handleQueTypeClick(e, "descriptive", 5)}
        >
          5 Marks
          <span>{state.ques_count.descriptiveCount}</span>
        </a>
      </div>
    </div>
  );
};

export default CategoryBoxSubject;
