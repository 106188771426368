import React, { useEffect, useState } from "react";
import styles from "../../styles/demoResponsive.module.css";
import classNames from "classnames";
import ReactSelect, { components } from "react-select";
import { useDispatch } from "react-redux";
import { setGenerateDiagram } from "../../store/reducers/generalActions";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactSwitch from "react-switch";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultipleOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const DIFFICULTY_OPTIONS = [
  { value: "Easy", label: "Easy" },
  { value: "Medium", label: "Medium" },
  { value: "Hard", label: "Hard" },
];

const CUSTOM_STYLES = {
  option: (provided, state) => ({
    ...provided,
  }),

  container: (base) => ({
    ...base,

    // border: this.state.occupation_message ? "1px solid red" : "",
    borderRadius: 5,
  }),

  // control: () => ({
  //   // none of react-select's styles are passed to <Control />
  //   border:"1px solid red"
  // }),
  menu: (provided, state) => ({
    ...provided,
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 13,
    color: "#8898aa",
  }),
};

const GenerateQuestionSpecialist = ({
  state,
  setState,
  is_new_pricing,
  props,
  setSubject,
  radioButtonValue,
  getSortedOptions,
  setTopicFormula,
  handleGenerateQuestion,
  handleCustomTopic,
  removeTopicMissmatchErr,
  setGrade,
  setPattern,
  handleMarks,
}) => {
  const dispatch = useDispatch();

  const [diagram, setDiagram] = useState([]);

  useEffect(() => {
    dispatch(setGenerateDiagram(0));
    setState({
      buttonType: "Easy",
      level_pcma: "easy level",
    });
  }, [dispatch]);

  useEffect(() => {
    const valueSelected = !_.isEmpty(diagram) ? diagram.value : 0;
    dispatch(setGenerateDiagram(valueSelected));
  }, [diagram]);

  // const handleDifficultyLevel = (value) => {
  //   if (value) {
  //     setDifficulty(value);
  //     switch (value.value) {
  //       case "Easy":
  //         setState({
  //           buttonType: "Easy",
  //           level_pcma: "easy level",
  //         });
  //         break;
  //       case "Medium":
  //         setState({
  //           buttonType: "Medium",
  //           level_pcma: "medium level",
  //         });
  //         break;
  //       case "Hard":
  //         setState({
  //           buttonType: "Hard",
  //           level_pcma: "hard level",
  //         });
  //         break;
  //       default:
  //         // Optional default case
  //         // You can add additional logic here if needed
  //         break;
  //     }
  //   } else {
  //     setDifficulty([]);
  //     setState({
  //       buttonType: "Easy",
  //       level_pcma: "easy level",
  //     });
  //   }
  // };

  return (
    <div
      className="question-generate-tab-outer maxw-820"
      style={{ position: "relative" }}
    >
      <div>
        <label
          for="selectques_type"
          className={classNames(
            styles["question-generate-tab-label"],
            "form-label d-flex align-items-center"
          )}
          style={{ fontWeight: 500, color: "#344054", gap: "1rem" }}
        >
          Select Pattern
        </label>
        <div className="text-left" style={{ position: "relative" }}>
          <ReactSelect
            hideSelectedOptions={false}
            className={classNames(styles["question-generate-tab-label"])}
            maxMenuHeight={200}
            components={{
              Option,
            }}
            options={state.patterns}
            type="text"
            id="selectPattern"
            onChange={setPattern}
            value={state.pattern_name}
            style={{ background: "#f8b8be !important" }}
          />
          {state.showTopicMismatchErr && (
            <div
              className="reveal-err"
              style={{
                position: "absolute",
                top: "0px",
                height: "38px",
                width: "100%",
                background: "#f8b8be80",
              }}
            ></div>
          )}
        </div>
        {state.errorEvent === "subject_name" && (
          <span className="error-msg-expertise">{state.errorMsg}</span>
        )}
      </div>
      <div style={{ gap: "1rem" }} className="mt-3 flex-row d-flex">
        <div className="position-relative" style={{ width: "50%" }}>
          <label
            for="selectques_type"
            className={classNames(
              styles["question-generate-tab-label"],
              "form-label d-flex align-items-center"
            )}
            style={{ fontWeight: 500, color: "#344054", gap: "1rem" }}
          >
            {state.pattern_name.value == "Competitive"
              ? "Select Use Case"
              : "Select Grade"}
          </label>
          <div className="text-left" style={{ position: "relative" }}>
            <ReactSelect
              hideSelectedOptions={false}
              className={classNames(styles["question-generate-tab-label"])}
              maxMenuHeight={200}
              components={{
                Option,
              }}
              options={
                state.pattern_name.value == "Competitive"
                  ? state.CompetitiveOption
                  : state.selectGrade
              }
              type="text"
              id="selectques_type"
              onChange={setGrade}
              value={
                state.pattern_name.value == "Competitive"
                  ? state.SelectedCompetitiveOption
                  : state.selected_Grade
              }
              style={{ background: "#f8b8be !important" }}
            />
          </div>

          {state.errorEvent === "topic_or_formula" && (
            <span className="error-msg-expertise">{state.errorMsg}</span>
          )}
        </div>
        <div className="position-relative" style={{ width: "50%" }}>
          <label
            for="selectques_type"
            className={classNames(
              styles["question-generate-tab-label"],
              "form-label d-flex align-items-center"
            )}
            style={{ fontWeight: 500, color: "#344054", gap: "1rem" }}
          >
            {state.pattern_name.value == "Competitive"
              ? "Question Type"
              : "Select Subject"}
          </label>
          <div className="text-left" style={{ position: "relative" }}>
            <ReactSelect
              hideSelectedOptions={false}
              className={classNames(styles["question-generate-tab-label"])}
              maxMenuHeight={200}
              components={{
                Option,
              }}
              options={
                state.pattern_name.value == "Competitive"
                  ? state.questionType
                  : state.subject[state.selected_Grade.value]
              }
              type="text"
              id="selectques_type"
              onChange={setSubject}
              value={
                state.pattern_name.value == "Competitive"
                  ? state.SelectedquestionType
                  : state.subject_name
              }
              style={{ background: "#f8b8be !important" }}
            />
          </div>

          {state.errorEvent === "topic_or_formula" && (
            <span className="error-msg-expertise">{state.errorMsg}</span>
          )}
        </div>
      </div>

      <div className="mt-3">
        <label
          for="selectques_type"
          className={classNames(
            styles["question-generate-tab-label"],
            "form-label d-flex "
          )}
          style={{ fontWeight: 500, color: "#344054", gap: "1rem" }}
        >
          {state.isCustomTopic ? "Custom topic" : "Select Chapter"}
        </label>
        {state.isCustomTopic ? (
          <input
            className={classNames(
              styles["question-generate-tab-input"],
              `custom-login-field--inputs form-control px-2`
            )}
            style={{
              height: "2.35rem",
              borderColor: "#cccccc",
              background: "none",
            }}
            type="text"
            value={state.customTopic}
            onChange={handleCustomTopic}
            placeholder={`Enter Custom ${
              state.key_pcma === 2 ? "Formula" : "Topic"
            }`}
          />
        ) : (
          <ReactSelect
            className={classNames(styles["question-generate-tab-label"])}
            // closeMenuOnSelect={false}
            hideSelectedOptions={false}
            maxMenuHeight={200}
            components={{
              Option,
            }}
            isClearable
            options={getSortedOptions()}
            type="text"
            id="selectDifficulty"
            placeholder={
              state.key_pcma === 2 ? "Select Formula" : "Select Topic"
            }
            onChange={setTopicFormula}
            value={state.chapter_formula_name}
          />
        )}

        <span
          style={{
            margin: 0,
            padding: 0,
            textDecorationLine: "underline",
            cursor: "pointer",
            fontSize: "10px",
            // marginTop: "10px",
            display: "flex",
            alignItems: "center",
            color: "#7A73D1",
            columnGap: "10px",
            width: "max-content", // Ensures the clickable area covers the text
          }}
          className={classNames(styles["custom_topic"])}
          onClick={() => setState({ isCustomTopic: !state.isCustomTopic })}
        >
          {state.isCustomTopic
            ? "Select predefined chapter?"
            : "Have custom topic?"}
        </span>
      </div>

      <div className="mt-3">
        <label
          for="selectques_type"
          className={classNames(
            styles["question-generate-tab-label"],
            "form-label d-flex align-items-center"
          )}
          style={{ fontWeight: 500, color: "#344054", gap: "4px" }}
        >
          Select Marks
        </label>
        <ReactSelect
          className={classNames(styles["question-generate-tab-label"])}
          isMulti
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          maxMenuHeight={200}
          components={{ Option: MultipleOption }}
          isClearable
          options={state.MARKS_OPTION}
          type="text"
          placeholder={
            state.selected_marks?.length > 0
              ? state.selected_marks.value
              : "All Possible"
          }
          id="selectmarks"
          onChange={handleMarks}
          name="marks"
          value={state.selected_marks}
          styles={CUSTOM_STYLES}
        />
      </div>
      <div
        className={classNames(
          "btn-flex100",
          styles["specialist-btn-margin-top-custom"]
        )}
      >
        <button
          onClick={handleGenerateQuestion}
          type="submit"
          className={classNames(
            styles["question-generate-submit"],
            "btn--submit"
          )}
        >
          {props.user.credits <= 1 && !props.user.plan_details.is_new_pricing
            ? "Buy Credits"
            : "Generate Questions"}
        </button>
      </div>
      {/* <SweetAlert
        show={state.isCustomTopic}
        showConfirm
        confirmBtnText="Generate Question"
        confirmBtnStyle={{ fontSize: "16px" }}
        confirmBtnBsStyle="success"
        onEscapeKey={() => setState({ isCustomTopic: false })}
        onOutsideClick={() => setState({ isCustomTopic: false })}
        onConfirm={handleGenerateQuestion}
        onCancel={() => setState({ isCustomTopic: false })}
      >
        <input
          className={classNames(
            styles["question-generate-tab-input"],
            `custom-login-field--inputs form-control px-2`
          )}
          style={{
            height: "2.35rem",
            borderColor: "#cccccc",
            background: "none",
          }}
          type="text"
          value={state.customTopic}
          onChange={handleCustomTopic}
          placeholder={`Enter Custom ${
            state.key_pcma === 2 ? "Formula" : "Topic"
          }`}
        />
      </SweetAlert> */}
    </div>
  );
};

export default GenerateQuestionSpecialist;
