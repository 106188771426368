import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addQuestion,
  addSubjectQuestion,
  editQuestionPaper,
} from "../../services";
import { stopLoader, startLoader } from "../../store/reducers/generalActions";
import MSG, { notifyError, notifySuccess } from "../../utils/Helper";
import _ from "lodash";
import { authLogout } from "../../store/reducers/auth";

class AddSubjectmcq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      alert: null,
      options: ["", "", "", ""],
      explanation: "N/A",
      category_type: 1,
      optionIndex: null,
      selectedOption: null,
      isOptionEmpty: null,
      option_emsg: "",
      titleofQuestion_emsg: "",
      content_id: props.questions[0].content_id,
      topic: props.questions[0].topic,
      isOptionEmpty: null,
    };
  }

  async componentDidMount() {
    var optionObj = this.state.options;
    optionObj.map((option, index) => {
      if (option.endsWith("*")) {
        this.setState({
          optionIndex: index,
        });
      }
    });
  }

  handleClickOutside = (evt) => {
    this.props.showalert(this.props.question, this.state.optionIndex);
  };

  add = (event) => {
    this.setState({
      question: event.target.value,
      titleofQuestion_emsg: "",
    });
  };

  scrollToBottom = () => {
    const position = document.getElementById("addeasymcqref");
  };

  validatequestion = () => {
    var que = this.state.question;

    if (que.trim() == "") {
      this.setState({
        titleofQuestion_emsg: "Please enter the question",
      });
      return false;
    }

    if (!que.endsWith("?")) {
      this.setState({
        titleofQuestion_emsg: "Please put ? in the end",
      });
      return false;
    }
    if (que.replace("?", "").length == 0) {
      this.setState({
        titleofQuestion_emsg: "Please enter a question.",
      });
      return false;
    }

    this.setState({
      titleofQuestion_emsg: "",
    });
    return true;
  };

  validateoption = () => {
    var opt = this.state.options;
    var option = opt.map((val) => val.replace("*", ""));
    var cnt = 0;
    if (option.length !== 4) {
      this.setState({
        isOptionEmpty: Math.max(0, option.length - 1),
        option_emsg: "Please Enter the option",
      });
      return false;
    }
    for (var i = 0; i < option.length; i++) {
      if (!option[i]) {
        this.setState({
          isOptionEmpty: i,
          option_emsg: "Please Enter the option",
        });
        return false;
      }
      if (option[i].trim() == "") {
        this.setState({
          isOptionEmpty: i,
          option_emsg: "Please Enter the option",
        });
        return false;
      }
    }
    if (this.state.optionIndex == null) {
      this.setState({
        option_emsg: "Please mark the correct answer.",
      });
      return false;
    }

    this.setState({
      option_emsg: "",
    });

    return true;
  };

  option = (event) => {
    var optionvalue = this.state.options;
    var index = event.target.name;
    optionvalue[index - 1] = event.target.value;
    this.setState({
      options: optionvalue,
    });
  };

  optionChange = (e) => {
    this.setState({
      isOptionEmpty: null,
      option_emsg: "",
    });

    let index = e.target.name;
    var optionValue = [...this.state.options];
    var val = optionValue[index - 1];
    if (val.endsWith("*")) {
      optionValue[index - 1] = e.target.value + " *";
    } else {
      optionValue[index - 1] = e.target.value;
    }

    this.setState({
      options: optionValue,
    });
  };

  handleExplanation = async (event) => {
    const value = event.target.value;
    this.setState({ explanation: value });
  };

  radioButtonValue = (event) => {
    this.setState({
      optionIndex: event.target.name - 1,
      selectedOption: event.target.value,
    });
  };

  handlesubmit = (e) => {
    // e.preventDefault();
    if (!this.validatequestion() || !this.validateoption()) {
      return false;
    }
    var dataObj = { ...this.state };
    if (this.state.optionIndex != null) {
      var optionObj = dataObj.options;
      var opt = optionObj.map((val) => val.replace("*", ""));
      opt[this.state.optionIndex] = opt[this.state.optionIndex] + " *";
      dataObj.options = opt;
    }

    // const data = this.state;
    this.doSubmit(dataObj);
  };

  doSubmit = async (req) => {
    this.props.dispatch(startLoader());
    const reqObj = {
      ...req,
      question: this.state.question,
      explanation: [this.state.explanation],
    };
    const reqq = { newquestions: reqObj };
    try {
      const { data: res } = await addSubjectQuestion(reqq);
      console.log(res);
      if (res.success == true) {
        notifySuccess(res.message);
        const allQuestions = res.response;
        this.props.parentCallback(allQuestions);
        this.discard();

        this.props.dispatch(stopLoader());
      } else {
        notifyError(res.message);
      }
      this.props.dispatch(stopLoader());
    } catch (err) {
      console.log(err);
    }
  };

  handleOnClickFunction = () => {
    this.handlesubmit();
    this.scrollToBottom();
  };

  discard = () => {
    this.props.discardquestion("easymcq");
  };

  render() {
    return (
      <React.Fragment>
        <div id="addeasymcqref" />
        <div className="each-question-wrapper-box">
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee">Q.</div>
            <input
              className={
                !this.state.titleofQuestion_emsg == ""
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              value={this.state.question}
              onChange={this.add}
              placeholder="Write your Question here..."
            />
            {this.state.titleofQuestion_emsg && (
              <span className="text-danger">
                {this.state.titleofQuestion_emsg}
              </span>
            )}
          </div>
          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label className="mordern-radiobox-label my-0">
                <input
                  type="radio"
                  name="1"
                  // onChange={this.handlecheck}
                  onChange={this.radioButtonValue}
                  checked={this.state.optionIndex == 0 ? true : false}
                />
                <span className="design"></span>
              </label>
            </div>
            <input
              className={
                this.state.isOptionEmpty == 0
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="1"
              value={this.state.options[0]}
              onChange={this.optionChange}
              placeholder="Option 1"
            />
          </div>
          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label className="mordern-radiobox-label my-0">
                <input
                  type="radio"
                  name="2"
                  // onChange={this.handlecheck}
                  onChange={this.radioButtonValue}
                  checked={this.state.optionIndex == 1 ? true : false}
                />
                <span className="design"></span>
              </label>
            </div>
            <input
              className={
                this.state.isOptionEmpty == 1
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="2"
              value={this.state.options[1]}
              onChange={this.optionChange}
              placeholder="Option 2"
            />
          </div>
          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label className="mordern-radiobox-label my-0">
                <input
                  type="radio"
                  name="3"
                  // onChange={this.handlecheck}
                  onChange={this.radioButtonValue}
                  checked={this.state.optionIndex == 2 ? true : false}
                />
                <span className="design"></span>
              </label>
            </div>
            <input
              className={
                this.state.isOptionEmpty == 2
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="3"
              value={this.state.options[2]}
              onChange={this.optionChange}
              placeholder="Option 3"
            />
          </div>
          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label className="mordern-radiobox-label my-0">
                <input
                  type="radio"
                  name="4"
                  // onChange={this.handlecheck}
                  onChange={this.radioButtonValue}
                  checked={this.state.optionIndex == 3 ? true : false}
                />
                <span className="design"></span>
              </label>
            </div>
            <input
              className={
                this.state.isOptionEmpty == 3
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="4"
              value={this.state.options[3]}
              onChange={this.optionChange}
              placeholder="Option 4"
            />
          </div>

          {this.state.option_emsg && (
            <span className="text-danger" style={{ marginLeft: "3em" }}>
              {this.state.option_emsg}
            </span>
          )}

          <div className="editable--questions">
            <div
              style={{
                position: "relative",
                width: "94%",
                textAlign: "left",
                marginTop: "10px",
              }}
              className="quetsion--edit--markquee p-0"
            >
              <label style={{ fontSize: "15px" }}>
                Add Explanation (Optional)
              </label>
              <textarea
                name="explanation"
                onChange={this.handleExplanation}
                className="form-control"
                rows={5}
              />
            </div>
          </div>

          <div className="editable-question-submitbox">
            <button
              type="button"
              role="button"
              className="preview--paper--btn font--400 font--14 w-110"
              onClick={this.handleOnClickFunction}
            >
              Add
            </button>
            <button
              role="button"
              type="putton"
              className="preview--button font--400 font--14 w-110 ml-2"
              onClick={this.discard}
            >
              Discard
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(AddSubjectmcq);
