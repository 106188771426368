// import lib
import { lazy } from "react";
import QuestionPaperGenerateBloom from "./../components/question-paper-generate-bloom";
import QuestionpaperGenerate from "./../components/question-paper-generate";
import SubjectExpertiseQuestions from "../components/subject-expertise-questions";

import QuestionpaperGenerateDemo from "../components/demo";
import QuestionPaperGenerateBloomDemo from "./../components/question-paper-generate-bloom-demo";
import SubjectExpertiseQuestionsDemo from "../components/subject-expertise-questions-demo";

const webRoutes = [
  {
    path: "/api-docs",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "dashboard" */ "./../components/api-docs")
    ),
  },
  {
    path: "/coupons-and-offers",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "dashboard" */ "./../components/coupons-and-offers"
      )
    ),
  },
  {
    path: "/question-paper",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "dashboard" */ "./../components/question-paper"
      )
    ),
  },
  {
    path: "/login",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "login" */ "./../components/login")
    ),
  },
  {
    path: "/unsubscribe",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "login" */ "./../components/unsubscribe")
    ),
  },
  {
    path: "/verification-required",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "verification.js" */ "./../components/verification"
      )
    ),
  },
  {
    path: "/register",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "register" */ "./../components/NewRegister")
    ),
  },
  {
    path: "/forgot-password",
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "forgotpassword" */ "./../components/forgotpassword"
      )
    ),
  },
  {
    path: "/password/reset/:token",
    exact: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "resetpassword" */ "./../components/auth/reset-password"
      )
    ),
  },
  {
    path: "/quiz-multiplier",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "quizMultiplier" */ "./../components/quizMultiplier"
      )
    ),
  },

  {
    path: "/edit-profile",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "editProfile" */ "./../components/edit-profile"
      )
    ),
  },
  {
    path: "/my-account",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(/* webpackChunkName: "myAccount" */ "./../components/my-account")
    ),
  },
  {
    path: "/plans",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(/* webpackChunkName: "PlansList" */ "./../components/plans-list")
    ),
  },
  {
    path: "/addon-plans",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(/* webpackChunkName: "PlansList" */ "./../components/Addons")
    ),
  },
  {
    path: "/plans-bloom",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "PlansList" */ "./../components/plans-list-bloom"
      )
    ),
  },
  {
    path: "/change-password",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "changePassword" */ "./../components/change-password"
      )
    ),
  },
  {
    path: "/generate-questions",
    exact: true,
    auth: true,
    component: QuestionpaperGenerate,
  },
  {
    path: "/generate-questions-blooms",
    exact: true,
    auth: true,
    component: QuestionPaperGenerateBloom,
  },
  {
    path: "/subject-expertise-questions",
    exact: true,
    auth: true,
    component: SubjectExpertiseQuestions,
  },
  // --- Demo Routes
  {
    path: "/demo",
    exact: true,
    auth: false,
    component: QuestionpaperGenerateDemo,
  },
  {
    path: "/demo-generate-questions-blooms",
    exact: true,
    auth: false,
    component: QuestionPaperGenerateBloomDemo,
  },
  {
    path: "/demo-subject-expertise-questions",
    exact: true,
    auth: false,
    component: SubjectExpertiseQuestionsDemo,
  },
  // --- Demo Routes End
  {
    path: "/question-paper-preview",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/question-paper-preview"
      )
    ),
  },
  {
    path: "/question-paper-preview-bloom",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/question-paper-preview-bloom"
      )
    ),
  },
  {
    path: "/question-paper-preview-expertise",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/question-paper-preview-expertise"
      )
    ),
  },
  {
    path: "/refer-prepai",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "referPrepai" */ "./../components/refer-prepai"
      )
    ),
  },
  {
    path: "/get-free-gold-plan",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "getFreeGoldPlan" */ "./../components/get-free-gold-plan"
      )
    ),
  },
  {
    path: "/register-user",
    exact: true,
    auth: false,
    component: lazy(() =>
      import(
        /* webpackChunkName: "appSumoRegister" */ "./../components/app-sumo-register"
      )
    ),
  },
  {
    path: "/verify",
    exact: true,
    auth: false,
    component: lazy(() =>
      import(
        /* webpackChunkName: "verifyAppsumoAccount" */ "./../components/verify-appsumo-account"
      )
    ),
  },
  {
    path: "/referral-code",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "verifyAppsumoAccount" */ "./../components/referral-code"
      )
    ),
  },
  {
    path: "/my-tests",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/my-tests"
      )
    ),
  },
  {
    path: "/my-assessments",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/my-assessments"
      )
    ),
  },
  {
    path: "/test-preview",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/test-preview"
      )
    ),
  },
  {
    path: "/assessment-preview",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/assessment-preview"
      )
    ),
  },
  {
    path: "/response-preview",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/response-preview"
      )
    ),
  },
  {
    path: "/test-preview-bloom",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/test-preview-bloom"
      )
    ),
  },
  {
    path: "/test-preview-subject",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/test-preview-subject"
      )
    ),
  },
  {
    path: "/self-assessment",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/SelfAssessment"
      )
    ),
  },
  {
    path: "/test-scores",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/test-scores"
      )
    ),
  },
  {
    path: "/test-report",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/test-report"
      )
    ),
  },
  {
    path: "/conduct-test",
    exact: true,
    survey: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/conduct-test"
      )
    ),
  },
  {
    path: "/test/verify/:token",
    exact: true,
    publicSurvey: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "../components/test-verification-email"
      )
    ),
  },
  {
    path: "/test/verify-code/:token",
    exact: true,
    publicSurvey: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "../components/test-verification-code"
      )
    ),
  },
  {
    path: "/user-management",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/enterprise-user-management"
      )
    ),
  },
  {
    path: "/dashboard",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/enterprise-dashboard"
      )
    ),
  },
  {
    path: "/mydashboard",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/solo-user-dashboard"
      )
    ),
  },
  {
    path: "/edit-member",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "editProfile" */ "../components/edit-enterprise-member"
      )
    ),
  },
];

export default webRoutes;
