import Select from "react-select";
import _, { iteratee } from "lodash";
import { Dialog, Typography } from "@mui/material";
import { DialogTitle } from "@mui/material";
import Dropzone from "react-dropzone-uploader";
import { Link } from "react-router-dom";
import Tooltip from "./tooltip";
import TimeInput from "./TimeInput";
import styles from "../../styles/demoResponsive.module.css";
import classNames from "classnames";
import CustomQuestions from "./CustomQuestions";
import ReactSwitch from "react-switch";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useRef, useState } from "react";
import ReactSelect from "react-select";
import LinkIcon from "@mui/icons-material/Link";
import UploadIcon from "@mui/icons-material/Upload";
import LoginIcon from "@mui/icons-material/Login";
const GenerateQuestionStdHots = ({
  is_bloom,
  is_new_pricing,
  setState,
  state,
  props,
  handleSubmit,
  handleTitleChange,
  tabChanged,
  handleChange,
  redirectToPlansPage,
  handleGenerateQuestion,
  updateActivityCount,
  handlePdfDocFileStatusChange,
  getDocPdfFromEvent,
  customPageHandler,
  handleDocPage,
  handleClearCustomPages,
  handleGenerateQuestionFromPdfDoc,
  isCustomDurationHandler,
  startTimeStampHandler,
  startTypingTime,
  endTimeStampHandler,
  handleClearCustomDuration,
  handleFileStatusChange,
  getFilesFromEvent,
  onInputChange,
  handleEnter,
  getContent,
  handleGenerateQuestionForWiki,
  videoExtractButtonContent,
  placeholders,
  handleWikiTopicSelect,
  handleWikiTopicFocus,
  handleWikiTopicBlur,
  getUrlContant,
  onUrlChange,
  handleEnterTopic,
  handleGenerateUrl,
  onInputChangeWiki,
  AudioExtractButtonContent,
}) => {
  const history = useHistory();
  const [uploadingType, setUploadingType] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const currentPath = history.location.pathname;
  const wordLimit =
    props?.user?.plan_details?.plan_name === "Lifetime" ? 5000 : 2500;
  const contentEditableRefs = useRef({
    wiki: null,
    "custom-tab": null,
    "Weburl-tab": null,
  });
  const handleContentModeChange = () => {
    localStorage.removeItem("hotsToggle");

    // Toggle between the two URLs
    const targetPath =
      currentPath === "/generate-questions"
        ? "/generate-questions-blooms"
        : "/generate-questions";

    history.push(targetPath);
  };

  const isCursorInside = (contentEditable) => {
    if (!contentEditable) return false;

    const selection = window.getSelection();
    if (!selection.rangeCount) return false; // No active selection

    const range = selection.getRangeAt(0);
    // Check if the selection is within the contentEditable
    return contentEditable.contains(range.startContainer);
  };

  // 1. Add a function to save and restore cursor position
  const saveCursorPosition = (contentEditable) => {
    // Only save if the element exists and has focus
    if (!contentEditable || !isCursorInside(contentEditable)) return null;

    const selection = window.getSelection();
    if (selection.rangeCount === 0) return null;

    const range = selection.getRangeAt(0);
    const preSelectionRange = range.cloneRange();
    preSelectionRange.selectNodeContents(contentEditable);
    preSelectionRange.setEnd(range.startContainer, range.startOffset);
    const start = preSelectionRange.toString().length;

    return {
      start,
      end: start + range.toString().length,
    };
  };

  const restoreCursorPosition = (contentEditable, savedPosition) => {
    if (!contentEditable || !savedPosition) return;

    // Find position in text node
    const charIndex = savedPosition.start;
    const range = document.createRange();
    range.setStart(contentEditable, 0);
    range.collapse(true);

    const nodeStack = [contentEditable];
    let node;
    let foundStart = false;
    let charCount = 0;

    while (!foundStart && (node = nodeStack.pop())) {
      if (node.nodeType === 3) {
        // Text node
        const nextCharCount = charCount + node.length;
        if (nextCharCount >= charIndex) {
          range.setStart(node, charIndex - charCount);
          foundStart = true;
        }
        charCount = nextCharCount;
      } else {
        // Push child nodes to stack in reverse order
        let i = node.childNodes.length;
        while (i--) {
          nodeStack.push(node.childNodes[i]);
        }
      }
    }

    range.collapse(true);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  };

  // 2. Modify applyRedWordFormatting to preserve cursor position
  const applyRedWordFormatting = () => {
    const contentEditable = contentEditableRefs.current[state.defaultActiveTab];
    if (!contentEditable) return;

    // Save cursor position before updating
    const savedPosition = saveCursorPosition(contentEditable);

    // Get text based on active tab
    let text;
    switch (state.defaultActiveTab) {
      case "Weburl-tab":
        text = state.webUrlContant || "";
        break;
      case "custom-tab":
        text = state.contentForQuestions || "";
        break;
      case "wiki":
        text = state.wikiContent || "";
        break;
      default:
        text = "";
    }

    // If text exists, always show it (even if placeholder was showing)
    if (text) {
      const words = text.split(/\s+/);
      const formattedText =
        words.length > wordLimit
          ? `${words
              .slice(0, wordLimit)
              .join(" ")} <span style="color: red;">${words
              .slice(wordLimit)
              .join(" ")}</span>`
          : text;

      // Update content and restore cursor
      contentEditable.innerHTML = formattedText;
      if (savedPosition) {
        restoreCursorPosition(contentEditable, savedPosition);
      }
      return;
    }

    // Otherwise, show placeholder if not focused
    if (!isCursorInside(contentEditable)) {
      const placeholderValue = getPlaceholderForTab(state.defaultActiveTab);
      contentEditable.innerHTML = `<span style="color: #aaa; pointer-events: none;">${placeholderValue}</span>`;
    }
  };

  // Helper function to get placeholder text for current tab
  const getPlaceholderForTab = (tabName) => {
    switch (tabName) {
      case "Weburl-tab":
        return placeholders.webUrlContant || "Enter URL content...";
      case "custom-tab":
        return placeholders.content || "Type or paste your content here...";
      case "wiki":
        return placeholders.wiki || "Wiki content will appear here...";
      default:
        return "Enter content...";
    }
  };

  // Handle input changes
  const handleInput = (e) => {
    const plainText = e.target.innerText || "";

    if (state.defaultActiveTab === "Weburl-tab") {
      setState((prevState) => ({ ...prevState, webUrlContant: plainText }));
    } else if (state.defaultActiveTab === "custom-tab") {
      setState((prevState) => ({
        ...prevState,
        contentForQuestions: plainText,
      }));
    } else if (state.defaultActiveTab === "wiki") {
      setState((prevState) => ({ ...prevState, wikiContent: plainText }));
    }

    // Add to handleInput
    console.log("Input event:", {
      activeTab: state.defaultActiveTab,
      plainText: e.target.innerText,
      existingContent:
        state.defaultActiveTab === "Weburl-tab"
          ? state.webUrlContant
          : state.defaultActiveTab === "custom-tab"
          ? state.contentForQuestions
          : state.wikiContent,
    });
  };

  const handleFocus = () => {
    let placeholderValue;
    if (state.defaultActiveTab === "Weburl-tab") {
      placeholderValue = placeholders.webUrlContant;
    } else if (state.defaultActiveTab === "custom-tab") {
      placeholderValue = placeholders.content;
    } else if (state.defaultActiveTab === "wiki") {
      placeholderValue = placeholders.wiki;
    }

    const contentEditable = contentEditableRefs.current[state.defaultActiveTab];
    if (contentEditable) {
      // Check if the current content is the placeholder
      if (contentEditable.innerText.trim() === placeholderValue) {
        contentEditable.innerHTML = ""; // Clear the placeholder
      }

      // Move the cursor to the end of the content
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(contentEditable);
      range.collapse(false); // Collapse range to the end
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handleBlur = () => {
    let placeholderValue;
    if (state.defaultActiveTab === "Weburl-tab") {
      placeholderValue = placeholders.webUrlContant;
    } else if (state.defaultActiveTab === "custom-tab") {
      placeholderValue = placeholders.content;
    } else if (state.defaultActiveTab === "wiki") {
      placeholderValue = placeholders.wiki;
    }

    const contentEditable = contentEditableRefs.current[state.defaultActiveTab];
    if (contentEditable) {
      const text = contentEditable.innerText.trim();

      // If the content is empty, restore the placeholder text
      if (!text) {
        contentEditable.innerHTML = `<span style="color: #aaa; pointer-events: none;">${placeholderValue}</span>`; // Placeholder text with styling
      }
    }
  };
  useEffect(() => {
    // Get element before any changes
    const contentEditable = contentEditableRefs.current[state.defaultActiveTab];

    // Skip if contentEditable doesn't exist yet
    if (!contentEditable) return;

    // Only save position if user is currently editing
    const savedPosition = isCursorInside(contentEditable)
      ? saveCursorPosition(contentEditable)
      : null;

    applyRedWordFormatting();

    // Only restore position or place at end if appropriate
    if (contentEditable) {
      if (savedPosition) {
        // User was editing, restore their position
        restoreCursorPosition(contentEditable, savedPosition);
      } else if (!isCursorInside(contentEditable)) {
        // No editing happening, show placeholder or set cursor at end as before
        const placeholderValue =
          state.defaultActiveTab === "Weburl-tab"
            ? placeholders.webUrlContant
            : state.defaultActiveTab === "custom-tab"
            ? placeholders.content
            : placeholders.wiki;

        contentEditable.innerHTML = `<span style="color: #aaa; pointer-events: none;">${placeholderValue}</span>`;
      }
    }
  }, [
    state.webUrlContant,
    state.contentForQuestions,
    state.wikiContent,
    state.defaultActiveTab,
  ]);

  // Add this new useEffect
  useEffect(() => {
    // This effect only handles tab changes to ensure content is loaded
    const contentEditable = contentEditableRefs.current[state.defaultActiveTab];
    if (!contentEditable) return;

    // Force apply formatting when tab changes to load content
    setTimeout(() => applyRedWordFormatting(), 0);
  }, [
    state.defaultActiveTab,
    state.webUrlContant,
    state.contentForQuestions,
    state.wikiContent,
    state.defaultActiveTab,
  ]); // Only runs when tab changes

  const videoTypes = [
    {
      value: "google-drive",
      label: "Google Drive Video URL",
      icons: <LinkIcon />,
    },
    { value: "manual", label: "Upload Video File", icons: <UploadIcon /> },
    { value: "youtube", label: "YouTube Video URL", icons: <LinkIcon /> },
  ];

  const AudioTypes = [
    {
      value: "google-drive",
      label: "Google Drive Audio URL",
      icons: <LinkIcon />,
    },
    { value: "manual", label: "Upload Audio File", icons: <UploadIcon /> },
    // { value: "youtube", label: "YouTube Audio URL", icons: <LinkIcon /> },
  ];

  // Custom option component
  const customSingleValue = ({ data }) => (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className={styles["selected_respon"]}
    >
      {data.icons && (
        <span className={styles["singleSelection"]}>{data.icons}</span>
      )}
      {data.label}
    </div>
  );

  const customAudioSingleValue = ({ data }) => (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className={styles["selected_respon"]}
    >
      {data.icons && (
        <span className={styles["singleSelection"]}>{data.icons}</span>
      )}
      {data.label}
    </div>
  );

  const customOption = (props) => {
    const { data, innerRef, innerProps, isFocused } = props;
    const modifiedInnerProps =
      data.value === "youtube"
        ? { ...innerProps, onClick: (e) => e.preventDefault() }
        : innerProps;

    return (
      <div
        ref={innerRef}
        {...modifiedInnerProps}
        className={styles["dropdown_video"]}
        style={{
          cursor: data.value === "youtube" ? "not-allowed" : "pointer", // Custom cursor
          backgroundColor: isFocused ? "#f0f0f0" : "white", // Hover effect
          color: isFocused ? "#333" : "#000",
        }}
      >
        {/* Wrap icon and text in a container */}
        <div style={{ display: "flex", alignItems: "center" }}>
          {data.icons && <span style={{ marginRight: 8 }}>{data.icons}</span>}
          {data.label}
        </div>
      </div>
    );
  };

  const customAudioOption = (props) => {
    const { data, innerRef, innerProps, isFocused } = props;
    const modifiedInnerProps =
      data.value === "youtube"
        ? { ...innerProps, onClick: (e) => e.preventDefault() }
        : innerProps;

    return (
      <div
        ref={innerRef}
        {...modifiedInnerProps}
        className={styles["dropdown_video"]}
        style={{
          cursor: data.value === "youtube" ? "not-allowed" : "pointer", // Custom cursor
          backgroundColor: isFocused ? "#f0f0f0" : "white", // Hover effect
          color: isFocused ? "#333" : "#000",
        }}
      >
        {/* Wrap icon and text in a container */}
        <div style={{ display: "flex", alignItems: "center" }}>
          {data.icons && <span style={{ marginRight: 8 }}>{data.icons}</span>}
          {data.label}
        </div>
      </div>
    );
  };

  const handleVideoChange = (selectedOption) => {
    setUploadingType(selectedOption.value);
    setState({
      driveVideo: null,
      isUploaded: false,
      videourl: null,
      videoFile: null,
    });
  };

  // const handleAudioChange = (selectedOption) => {
  //   setUploadingType(selectedOption.value);
  //   setState({
  //     driveAudio: null,
  //     isUploadedAudio: false,
  //     audiourl: null,
  //     audioFile: null,
  //   });
  // };

  const validateExtension = (mimeType) => {
    // Validate video file extensions (e.g., mp4, mov)
    const allowedMimeTypes = [
      "video/mp4",
      "video/x-msvideo",
      "video/quicktime",
    ];
    return allowedMimeTypes.includes(mimeType);
  };

  function driveFunction(e) {
    let url = e.target.value;
    setState({ driveVideo: url, isUploaded: true });
  }
  function handleToggle() {
    setIsSearch((prev) => !prev);
    setState({ tmpselectedWikiTopic: "", wikiContent: "" });
  }

  useEffect(() => {
    if (
      state.tmpselectedWikiTopic &&
      typeof state.tmpselectedWikiTopic === "object"
    ) {
      setIsSearch(true);
    }
  });

  const isSwitchChecked = currentPath !== "/generate-questions";
  return (
    <div
      // style={{ minHeight: "44rem" }}
      className="question-generate-tab-outer maxw-820"
    >
      <form onSubmit={handleSubmit}>
        <div className="form-group custom-login-field">
          <div className="d-flex justify-content-between align-items-center">
            <label
              htmlFor="titleofQuestion"
              className="custom-login-field--label"
            >
              Quiz Title
            </label>
            <div
              className="d-flex align-items-center"
              style={{ gap: "7px", marginBottom: "12px" }}
            >
              <label
                htmlFor="titleofQuestion"
                className="custom-login-field--label"
                style={{
                  fontSize: "13px",
                  fontWeight: "700",
                  margin: 0,
                  padding: 0,
                }}
              >
                Bloom's Taxonomy
              </label>
              <ReactSwitch
                onColor="#49c0a0"
                height={20}
                width={36}
                // checkedIcon={false}
                // uncheckedIcon={false}
                checked={isSwitchChecked}
                onChange={handleContentModeChange}
              />
            </div>
          </div>
          <div className="position-relative">
            <input
              className={classNames(
                styles["question-generate-tab-input"],
                `custom-login-field--inputs form-control px-2 ${
                  state.titleofQuestion_emsg != "" ? "is-invalid" : ""
                }`
              )}
              type="text"
              name="titleofQuestion"
              value={state.titleofQuestion}
              onChange={handleTitleChange}
              id="titleofQuestion"
              aria-describedby="textHelp"
              placeholder={placeholders.title}
              onClick={() =>
                setState({
                  activity_count: state.activity_count + 1,
                })
              }
            />
            {state.titleofQuestion_emsg && (
              <span className="text-danger">{state.titleofQuestion_emsg}</span>
            )}
          </div>
        </div>
        <div className={classNames(styles["border-zero"], "navtab--outer")}>
          <label
            htmlFor=""
            className={classNames(
              styles["question-generate-tab-label"],
              "custom-login-field--label"
            )}
          >
            Content Type
          </label>
          <ul
            className={classNames(
              styles["question-generate-nav"],
              styles["border-zero"],
              styles["nav-link"],
              "nav "
            )}
            id="myTab"
            role="tablist"
          >
            <li
              className={classNames(
                styles[`question-generate-item`],
                `nav- ${
                  state.defaultActiveTab == "custom-tab"
                    ? styles["apply-bg"]
                    : ""
                }`
              )}
              role="presentation"
              onClick={() =>
                setState({
                  defaultActiveTab: "custom-tab",
                })
              }
            >
              <a
                className={classNames(
                  styles[`question-generate-item-text`],
                  styles["border-zero"],
                  `nav-link`
                )}
                id="custom-tab"
                // data-toggle="tab"
                href="#custom"
                role="tab"
                aria-controls="custom"
                aria-selected="false"
                onClick={(e) => (
                  //trackEvent(("Type or Paste Text Tab Clicked"),
                  tabChanged(2),
                  setState({
                    activity_count: state.activity_count + 1,
                  })
                )}
              >
                Text
              </a>
            </li>
            <li
              className={classNames(
                styles[`question-generate-item`],
                `nav- ${
                  state.defaultActiveTab == "Weburl-tab"
                    ? styles["apply-bg"]
                    : ""
                }`
              )}
              role="presentation"
              onClick={() =>
                setState({
                  defaultActiveTab: "Weburl-tab",
                })
              }
            >
              <a
                className={classNames(
                  styles[`question-generate-item-text`],
                  styles["border-zero"],
                  `nav-link`
                )}
                id="Weburl-tab"
                // data-toggle="tab"
                href="#Weburl"
                role="tab"
                aria-controls="Weburl"
                aria-selected="false"
                onClick={(e) => (
                  //trackEvent(("Type or Paste Text Tab Clicked"),
                  tabChanged(1),
                  setState({
                    activity_count: state.activity_count + 1,
                  })
                )}
              >
                URL
              </a>
            </li>
            <li
              className={classNames(
                styles[`question-generate-item`],
                `nav-item ${
                  state.defaultActiveTab == "wiki" ? styles["apply-bg"] : ""
                }`
              )}
              onClick={() =>
                setState({
                  defaultActiveTab: "wiki",
                })
              }
              role="presentation"
            >
              <a
                className={classNames(
                  styles[`question-generate-item-text`],
                  styles["border-zero"],
                  `nav-link`
                )}
                id="wiki-tab"
                // data-toggle="tab"
                href="#wiki"
                role="tab"
                aria-controls="wiki"
                aria-selected="true"
                onClick={(e) => (
                  //trackEvent(("Topic Search Tab Clicked"),
                  tabChanged(1),
                  setState({
                    activity_count: state.activity_count + 1,
                  })
                )}
              >
                Topic{" "}
              </a>
              {/* <span className="pro-tag badge">Pro</span> */}
            </li>
            <li
              className={classNames(
                styles[`question-generate-item`],
                `nav-item ${
                  state.defaultActiveTab == "upload-tab"
                    ? styles["apply-bg"]
                    : ""
                }`
              )}
              style={{ position: "relative" }}
              role="presentation"
              onClick={() =>
                setState({
                  defaultActiveTab: "upload-tab",
                })
              }
            >
              <a
                className={classNames(
                  styles[`question-generate-item-text`],
                  styles["border-zero"],
                  `nav-link`
                )}
                id="PDF-tab"
                // data-toggle="tab"
                href="#PDF"
                role="tab"
                aria-controls="PDF"
                aria-selected="false"
                //style={{ paddingLeft: "8px", textAlign: "left" }}
                onClick={(e) => (
                  //trackEvent(("Upload PDF/Docx Tab Clicked"),
                  tabChanged(4),
                  setState({
                    activity_count: state.activity_count + 1,
                  })
                )}
              >
                Document
              </a>
            </li>
            <li
              className={classNames(
                styles[`question-generate-item`],
                `nav-item ${
                  state.defaultActiveTab == "video-tab"
                    ? styles["apply-bg"]
                    : ""
                }`
              )}
              style={{ position: "relative" }}
              role="presentation"
              onClick={() =>
                setState({
                  defaultActiveTab: "video-tab",
                })
              }
            >
              <a
                className={classNames(
                  styles[`question-generate-item-text`],
                  styles["border-zero"],
                  `nav-link`
                )}
                id="VideoUrl-tab"
                // data-toggle="tab"
                href="#VideoUrl"
                role="tab"
                aria-controls="VideoUrl"
                aria-selected="false"
                onClick={(e) => (
                  //trackEvent(("Video Tab Clicked"),
                  tabChanged(3),
                  setState({
                    activity_count: state.activity_count + 1,
                  })
                )}
              >
                Video
                {/* {is_new_pricing === 1 && (
                  <img
                    src="img/crown.jpg"
                    className={classNames(styles["video-crown"])}
                    alt="icon"
                    width="30"
                    height="30"
                    style={{
                      position: "absolute",
                      top: "-4px",
                      marginLeft: "-7px",
                    }}
                  />
                )} */}
              </a>
            </li>
            <li
              className={classNames(
                styles[`question-generate-item`],
                `nav-item ${
                  state.defaultActiveTab == "audio-tab"
                    ? styles["apply-bg"]
                    : ""
                }`
              )}
              style={{ position: "relative" }}
              role="presentation"
              onClick={() =>
                setState({
                  defaultActiveTab: "audio-tab",
                })
              }
            >
              <a
                className={classNames(
                  styles[`question-generate-item-text`],
                  styles["border-zero"],
                  `nav-link`
                )}
                id="VideoUrl-tab"
                // data-toggle="tab"
                href="#VideoUrl"
                role="tab"
                aria-controls="VideoUrl"
                aria-selected="false"
                onClick={(e) => (
                  //trackEvent(("Video Tab Clicked"),
                  tabChanged(3),
                  setState({
                    activity_count: state.activity_count + 1,
                  })
                )}
              >
                Audio
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${
                state.defaultActiveTab == "custom-tab" ? "active show" : ""
              }`}
              id="custom"
              role="tabpanel"
              aria-labelledby="custom-tab"
            >
              <div className="form-group custom-login-field">
                <div className="position-relative" style={{ height: "400px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <label
                      htmlFor="contentForQuestions"
                      className={classNames(
                        styles["question-generate-tab-label"],
                        "custom-login-field--label"
                      )}
                    >
                      Type/Paste your Content here
                    </label>
                    <span
                      className={styles["ext-word"]}
                      style={{
                        color:
                          state.contentForQuestions
                            ?.split(/\s+/)
                            .filter((word) => word.length > 0).length >
                          wordLimit
                            ? "red"
                            : "",
                      }}
                    >
                      Words:{" "}
                      {state.contentForQuestions
                        ? state.contentForQuestions
                            .split(/\s+/)
                            .filter((word) => word.length > 0).length
                        : 0}
                    </span>
                  </div>

                  {state.defaultActiveTab === "custom-tab" && (
                    <div
                      ref={(el) =>
                        (contentEditableRefs.current["custom-tab"] = el)
                      } // Attach the ref to the contentEditable div
                      contentEditable={true}
                      suppressContentEditableWarning={true}
                      className={classNames(
                        styles["question-generate-tab-input"],

                        styles["border-active"],

                        "custom-login-field--inputs form-control px-2 resize--none"
                      )}
                      onInput={handleInput}
                      onFocus={handleFocus} // Prevent jumping when clicking
                      onBlur={handleBlur}
                      style={{
                        minHeight: "87.8%",
                        maxHeight: "87.8%",
                        padding: "10px",
                        overflowY: "auto",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                      }}
                    ></div>
                  )}
                  {state.showWordLimit && (
                    <span className={styles["word_limit"]}>
                      Please enter at least 100 words
                    </span>
                  )}
                </div>

                {state.currentActiveTab === 2 && (
                  <CustomQuestions isblooms={is_bloom} />
                )}

                {/* Generate Questions Button */}
                <div className="btn-flex100">
                  {/* Filter Section */}
                  {/* <Filter/> */}

                  <button
                    type="button"
                    className={classNames(
                      styles["question-generate-submit"],
                      "btn--submit"
                    )}
                    onClick={
                      props.user.credits <= 1 &&
                      !props.user.plan_details.is_new_pricing &&
                      is_bloom
                        ? redirectToPlansPage
                        : handleGenerateQuestion
                    }
                    disabled={
                      state.contentForQuestions
                        ? false
                        : props?.user?.plan_details?.is_new_pricing
                        ? true
                        : false
                    }
                  >
                    {props.user.credits <= 1 &&
                    !props.user.plan_details.is_new_pricing &&
                    is_bloom
                      ? "Buy Credits"
                      : "Generate Questions"}
                  </button>
                </div>
                {/*  */}
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                state.defaultActiveTab == "upload-tab" ? "active show" : ""
              }`}
              id="PDF"
              role="tabpanel"
              aria-labelledby="PDF-tab"
            >
              <div className="position-relative" style={{ height: "400px" }}>
                <label>Upload a Document Here</label>
                <div
                  className={classNames(
                    styles["border-active"],
                    "video--content "
                  )}
                  style={{ height: "87.8%" }}
                >
                  <div
                    className={`m-auto text-center video--content-font ${
                      !_.isEmpty(props.user) &&
                      !props.user.plan_details.upload_pdf_doc_is_allowed
                        ? "sm-disabled"
                        : ""
                    }`}
                    style={{ width: "87%" }}
                    onClick={updateActivityCount}
                  >
                    <Dropzone
                      accept=".pdf, .PDF, .docx, .doc, .ppt, .pptx"
                      maxFiles={1}
                      canCancel={true}
                      data-tip
                      inputContent="Drag Files or Click to Browse (.pdf, .docx, .doc, .ppt, .pptx)"
                      onChangeStatus={handlePdfDocFileStatusChange}
                      getUploadParams={() => ({
                        url: "https://httpbin.org/post",
                      })}
                      // InputComponent={Input}
                      getFilesFromEvent={getDocPdfFromEvent}
                      styles={{
                        dropzone: {
                          maxHeight: 250,
                          wordBreak: "break-word",
                        },
                      }}
                      disabled={
                        !_.isEmpty(props.user) &&
                        !props.user.plan_details.upload_pdf_doc_is_allowed
                      }
                    />
                    {state.pdfDocFile && (
                      <div
                        style={{
                          display: "inline-flex",
                          gap: "10px",
                          alignItems: "center",
                          marginTop: "1rem",
                        }}
                      >
                        {!state.isCustomPages ? (
                          <div
                            style={{
                              cursor: "pointer",
                              color: "#49c0a0",
                            }}
                            onClick={customPageHandler}
                          >
                            Click here to select custom pages.
                          </div>
                        ) : (
                          <div className="get-doc-pages">
                            {/* <label htmlFor="page-no">
                      {state.docPageStatement}
                    </label> */}
                            <div className="notify-tool-tip">
                              <i
                                style={{
                                  color: "#007bff",
                                  marginTop: "0.25rem",
                                  cursor: "pointer",
                                }}
                                data-for="iota"
                                className="fas fa-info-circle"
                              ></i>
                              <span className="hoverText">
                                Type page numbers and/or page ranges, seprated
                                by commas. For example: 1,3,5-12,14.
                              </span>
                            </div>
                            <input
                              id="page-no"
                              type="text"
                              onChange={handleDocPage}
                              value={state.docPage}
                              placeholder="1,3,5-12,14"
                            />
                            {state.docPage && (
                              <span
                                style={{
                                  fontWeight: "700",
                                  cursor: "pointer",
                                }}
                                onClick={handleClearCustomPages}
                              >
                                x
                              </span>
                            )}
                          </div>
                        )}
                        {/* <span
                      style={{ height: "1.2rem" }}
                      className="badge badge-info"
                    >
                      BETA
                    </span> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Custom dropzone code goes here */}

              {state.currentActiveTab === 4 && (
                <CustomQuestions isblooms={is_bloom} />
              )}

              <div className="btn-flex100">
                {/* Filter Section */}
                {/* <Filter/> */}

                <button
                  type="submit"
                  className={classNames(
                    styles["question-generate-submit"],
                    "btn--submit"
                  )}
                  onClick={
                    props.user.credits <= 1 &&
                    !props.user.plan_details.is_new_pricing &&
                    is_bloom
                      ? redirectToPlansPage
                      : handleGenerateQuestionFromPdfDoc
                  }
                  disabled={
                    state.pdfDocFile != null
                      ? false
                      : props?.user?.plan_details?.is_new_pricing
                      ? true
                      : false
                  }
                >
                  {props.user.credits <= 1 &&
                  !props.user.plan_details.is_new_pricing &&
                  is_bloom
                    ? "Buy Credits"
                    : "Generate Questions"}
                </button>
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                state.defaultActiveTab == "video-tab" ? "active show" : ""
              }`}
              id="VideoUrl"
              role="tabpanel"
              aria-labelledby="VideoUrl-tab"
            >
              {/* Video Content Goes Here */}
              <div className="position-relative" style={{ height: "400px" }}>
                <label>Upload Video/URL</label>

                <div
                  className={classNames(
                    styles["border-active"],
                    "video--content "
                  )}
                  style={{ height: "87.8%" }}
                >
                  <div
                    className="m-auto text-center video--content-font"
                    style={{ width: "70%" }}
                  >
                    <label>Select the Source </label>
                    <ReactSelect
                      options={videoTypes}
                      placeholder="Select a source..."
                      onChange={handleVideoChange}
                      components={{
                        Option: customOption,
                        SingleValue: customSingleValue,
                      }}
                    />
                  </div>
                  {/* <h5 className="mt-2 mb-1 text-center videoordiv">
                  <span>OR</span>
                </h5> */}
                  {uploadingType === "youtube" && (
                    <div
                      className="m-auto text-center video--content-font"
                      style={{ width: "70%" }}
                    >
                      <label htmlFor=""> Enter a Video URL </label>
                      {/* <p className="finger-pointing-down"></p> */}

                      <div className="videoImg-content">
                        <img
                          src={`${process.env.REACT_APP_PROJECT_URL}/img/videoURL.svg`}
                          alt="videoImage"
                        />
                        <input
                          style={{
                            cursor: "not-allowed",
                            opacity: "0.5",
                          }}
                          type="text"
                          className={`voideourl--text ${
                            !_.isEmpty(props.user) &&
                            !props.user.plan_details.video_is_allowed
                              ? ""
                              : ""
                          }`}
                          name="videourl"
                          data-tip
                          data-for="upload_video"
                          value={state.videourl}
                          onChange={handleChange}
                          placeholder="Paste Video URL from Youtube"
                          disabled
                          onClick={() =>
                            setState({
                              activity_count: state.activity_count + 1,
                            })
                          }
                        />
                        <Tooltip
                          id="upload_video"
                          title="You can either use YouTube URL or Upload video. This feature is currently disabled."
                        />
                      </div>

                      {state.videourl && (
                        <div
                          style={{
                            display: "inline-flex",
                            gap: "10px",
                            alignItems: "center",
                            position: "relative",
                            marginTop: "1rem",
                          }}
                        >
                          {!state.isCustomDuration ? (
                            <div
                              style={{
                                cursor: "pointer",
                                color: "#49c0a0",
                              }}
                              onClick={isCustomDurationHandler}
                            >
                              Click here to select Duration.
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <TimeInput
                                  id="startTime"
                                  timeStampHandler={(secs) =>
                                    startTimeStampHandler(secs)
                                  }
                                  startTyping={startTypingTime}
                                  isValid={state.timeInputMsg}
                                />
                                to
                                <TimeInput
                                  id="endTime"
                                  timeStampHandler={(secs) =>
                                    endTimeStampHandler(secs)
                                  }
                                  startTyping={startTypingTime}
                                  isValid={state.timeInputMsg}
                                />
                                <span
                                  style={{
                                    fontWeight: "700",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleClearCustomDuration}
                                >
                                  x
                                </span>
                              </div>
                              <div
                                style={{
                                  color: "#dc3545",
                                  fontWeight: "500",
                                  marginTop: "6px",
                                  fontSize: "13px",
                                }}
                                className="text-left"
                              >
                                {state.timeInputMsg}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {uploadingType === "manual" && (
                    <div
                      className="m-auto text-center video--content-font"
                      style={{ width: "70%" }}
                    >
                      <div className="videoImg-content">
                        <label htmlFor="" className="mt-1">
                          Upload Video File
                        </label>
                        <div
                          className={`${
                            !_.isEmpty(props.user) &&
                            !props.user.plan_details.video_is_allowed
                              ? "sm-disabled"
                              : ""
                          }`}
                          onClick={updateActivityCount}
                        >
                          <Dropzone
                            name="video-dropzone"
                            accept="video/*"
                            maxFiles={1}
                            canCancel={true}
                            data-tip
                            data-for="upload_video"
                            inputContent="Drag Files or Click to Browse(.mp4, .avi, .mov, .wmv)"
                            onChangeStatus={handleFileStatusChange}
                            getUploadParams={() => ({
                              url: "https://httpbin.org/post",
                            })}
                            // InputComponent={Input}
                            getFilesFromEvent={getFilesFromEvent}
                            disabled={
                              state.videourl
                                ? true
                                : false ||
                                  (!_.isEmpty(props.user) &&
                                    !props.user.plan_details.video_is_allowed)
                            }
                            styles={{
                              dropzone: {
                                maxHeight: 250,
                                wordBreak: "break-word",
                                // border: "2px dashed #c6c6c6",
                                // borderWidth: "2px",
                              },
                            }}
                          />
                          {state.videoFile && (
                            <div
                              style={{
                                display: "inline-flex",
                                gap: "10px",
                                position: "relative",
                                alignItems: "center",
                                marginTop: "1rem",
                              }}
                            >
                              {!state.isCustomDuration ? (
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "#49c0a0",
                                  }}
                                  onClick={isCustomDurationHandler}
                                >
                                  Click here to select Duration.
                                </div>
                              ) : (
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <TimeInput
                                      id="startTime"
                                      timeStampHandler={(secs) =>
                                        startTimeStampHandler(secs)
                                      }
                                      startTyping={startTypingTime}
                                      isValid={state.timeInputMsg}
                                    />
                                    to
                                    <TimeInput
                                      id="endTime"
                                      timeStampHandler={(secs) =>
                                        endTimeStampHandler(secs)
                                      }
                                      startTyping={startTypingTime}
                                      isValid={state.timeInputMsg}
                                    />
                                    <span
                                      style={{
                                        fontWeight: "700",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleClearCustomDuration}
                                    >
                                      x
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      color: "#dc3545",
                                      fontWeight: "500",
                                      marginTop: "6px",
                                      fontSize: "13px",
                                    }}
                                    className="text-left"
                                  >
                                    {state.timeInputMsg}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {uploadingType === "google-drive" && (
                    <div
                      className="m-auto text-center video--content-font"
                      style={{ width: "70%" }}
                    >
                      <label htmlFor=""> Enter a Google Drive URL </label>
                      {/* <p className="finger-pointing-down"></p> */}

                      <div className="videoImg-content">
                        <img
                          src={`${process.env.REACT_APP_PROJECT_URL}/img/videoURL.svg`}
                          alt="videoImage"
                        />
                        <input
                          style={{
                            // cursor: "not-allowed",
                            opacity: "1",
                          }}
                          type="text"
                          className={`voideourl--text ${
                            !_.isEmpty(props.user) &&
                            !props.user.plan_details.video_is_allowed
                              ? ""
                              : ""
                          }`}
                          name="videourl"
                          data-tip
                          data-for="upload_video"
                          value={state.driveVideo}
                          onChange={driveFunction}
                          placeholder="Paste Video URL from Gdrive"
                          // disabled
                          onClick={() =>
                            setState({
                              activity_count: state.activity_count + 1,
                            })
                          }
                        />
                        {/* <Tooltip
                        id="upload_video"
                        title="You can either use YouTube URL or Upload video. This feature is currently disabled."
                      /> */}
                      </div>

                      {state.driveVideo && (
                        <div
                          style={{
                            display: "inline-flex",
                            gap: "10px",
                            alignItems: "center",
                            position: "relative",
                            marginTop: "1rem",
                          }}
                        >
                          {!state.isCustomDuration ? (
                            <div
                              style={{
                                cursor: "pointer",
                                color: "#49c0a0",
                              }}
                              onClick={isCustomDurationHandler}
                            >
                              Click here to select Duration.
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <TimeInput
                                  id="startTime"
                                  timeStampHandler={(secs) =>
                                    startTimeStampHandler(secs)
                                  }
                                  startTyping={startTypingTime}
                                  isValid={state.timeInputMsg}
                                />
                                to
                                <TimeInput
                                  id="endTime"
                                  timeStampHandler={(secs) =>
                                    endTimeStampHandler(secs)
                                  }
                                  startTyping={startTypingTime}
                                  isValid={state.timeInputMsg}
                                />
                                <span
                                  style={{
                                    fontWeight: "700",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleClearCustomDuration}
                                >
                                  x
                                </span>
                              </div>
                              <div
                                style={{
                                  color: "#dc3545",
                                  fontWeight: "500",
                                  marginTop: "6px",
                                  fontSize: "13px",
                                }}
                                className="text-left"
                              >
                                {state.timeInputMsg}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {/* Video Content Goes Here */}
              {state.currentActiveTab === 3 && (
                <CustomQuestions isblooms={is_bloom} />
              )}

              <div className="btn-flex100 video-section">
                {/*<button
                type="button"
                className="btn--submit"
                onClick={handleGenerateQuestionFromVideo}
                disabled={
                  state.videourl || state.isUploaded
                    ? false
                    : true
                }
              >
                Extract Content
              </button> */}

                {/* Filter Section */}
                {/* <Filter/> */}
                {videoExtractButtonContent()}
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                state.defaultActiveTab == "audio-tab" ? "active show" : ""
              }`}
              id="audioUrl"
              role="tabpanel"
              aria-labelledby="audioUrl-tab"
            >
              {/* Video Content Goes Here */}
              <div className="position-relative" style={{ height: "400px" }}>
                <label>Upload Audio/URL</label>

                <div
                  className={classNames(
                    styles["border-active"],
                    "video--content "
                  )}
                  style={{ height: "87.8%" }}
                >
                  <div
                    className="m-auto text-center video--content-font"
                    style={{ width: "70%" }}
                  >
                    <label>Select the Source </label>
                    <ReactSelect
                      options={AudioTypes}
                      placeholder="Select a source..."
                      onChange={handleVideoChange}
                      components={{
                        Option: customAudioOption,
                        SingleValue: customAudioSingleValue,
                      }}
                    />
                  </div>

                  {uploadingType === "youtube" && (
                    <div
                      className="m-auto text-center video--content-font"
                      style={{ width: "70%" }}
                    >
                      <label htmlFor=""> Enter a Video URL </label>
                      {/* <p className="finger-pointing-down"></p> */}

                      <div className="videoImg-content">
                        <img
                          src={`${process.env.REACT_APP_PROJECT_URL}/img/videoURL.svg`}
                          alt="videoImage"
                        />
                        <input
                          style={{
                            cursor: "not-allowed",
                            opacity: "0.5",
                          }}
                          type="text"
                          className={`voideourl--text ${
                            !_.isEmpty(props.user) &&
                            !props.user.plan_details.video_is_allowed
                              ? ""
                              : ""
                          }`}
                          name="videourl"
                          data-tip
                          data-for="upload_video"
                          value={state.videourl}
                          onChange={handleChange}
                          placeholder="Paste Video URL from Youtube"
                          disabled
                          onClick={() =>
                            setState({
                              activity_count: state.activity_count + 1,
                            })
                          }
                        />
                        <Tooltip
                          id="upload_video"
                          title="You can either use YouTube URL or Upload video. This feature is currently disabled."
                        />
                      </div>

                      {state.videourl && (
                        <div
                          style={{
                            display: "inline-flex",
                            gap: "10px",
                            alignItems: "center",
                            position: "relative",
                            marginTop: "1rem",
                          }}
                        >
                          {!state.isCustomDuration ? (
                            <div
                              style={{
                                cursor: "pointer",
                                color: "#49c0a0",
                              }}
                              onClick={isCustomDurationHandler}
                            >
                              Click here to select Duration.
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <TimeInput
                                  id="startTime"
                                  timeStampHandler={(secs) =>
                                    startTimeStampHandler(secs)
                                  }
                                  startTyping={startTypingTime}
                                  isValid={state.timeInputMsg}
                                />
                                to
                                <TimeInput
                                  id="endTime"
                                  timeStampHandler={(secs) =>
                                    endTimeStampHandler(secs)
                                  }
                                  startTyping={startTypingTime}
                                  isValid={state.timeInputMsg}
                                />
                                <span
                                  style={{
                                    fontWeight: "700",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleClearCustomDuration}
                                >
                                  x
                                </span>
                              </div>
                              <div
                                style={{
                                  color: "#dc3545",
                                  fontWeight: "500",
                                  marginTop: "6px",
                                  fontSize: "13px",
                                }}
                                className="text-left"
                              >
                                {state.timeInputMsg}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {uploadingType === "manual" && (
                    <div
                      className="m-auto text-center video--content-font"
                      style={{ width: "70%" }}
                    >
                      <div className="videoImg-content">
                        <label htmlFor="" className="mt-1">
                          Upload Audio File
                        </label>
                        <div
                          // className={`${
                          //   !_.isEmpty(props.user) &&
                          //   !props.user.plan_details.video_is_allowed
                          //     ? "sm-disabled"
                          //     : ""
                          // }`}
                          onClick={updateActivityCount}
                        >
                          <Dropzone
                            name="audio-dropzone"
                            accept="audio/*"
                            maxFiles={1}
                            canCancel={true}
                            data-tip
                            data-for="upload_audio"
                            inputContent="Drag Files or Click to Browse(.wav, .mp3)"
                            onChangeStatus={handleFileStatusChange}
                            getUploadParams={() => ({
                              url: "https://httpbin.org/post",
                            })}
                            // InputComponent={Input}
                            getFilesFromEvent={getFilesFromEvent}
                            disabled={
                              state.videourl
                                ? true
                                : false ||
                                  (!_.isEmpty(props.user) &&
                                    !props.user.plan_details.video_is_allowed)
                            }
                            styles={{
                              dropzone: {
                                maxHeight: 250,
                                wordBreak: "break-word",
                              },
                            }}
                          />
                          {state.videoFile && (
                            <div
                              style={{
                                display: "inline-flex",
                                gap: "10px",
                                position: "relative",
                                alignItems: "center",
                                marginTop: "1rem",
                              }}
                            >
                              {!state.isCustomDuration ? (
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "#49c0a0",
                                  }}
                                  onClick={isCustomDurationHandler}
                                >
                                  Click here to select Duration.
                                </div>
                              ) : (
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <TimeInput
                                      id="startTime"
                                      timeStampHandler={(secs) =>
                                        startTimeStampHandler(secs)
                                      }
                                      startTyping={startTypingTime}
                                      isValid={state.timeInputMsg}
                                    />
                                    to
                                    <TimeInput
                                      id="endTime"
                                      timeStampHandler={(secs) =>
                                        endTimeStampHandler(secs)
                                      }
                                      startTyping={startTypingTime}
                                      isValid={state.timeInputMsg}
                                    />
                                    <span
                                      style={{
                                        fontWeight: "700",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleClearCustomDuration}
                                    >
                                      x
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      color: "#dc3545",
                                      fontWeight: "500",
                                      marginTop: "6px",
                                      fontSize: "13px",
                                    }}
                                    className="text-left"
                                  >
                                    {state.timeInputMsg}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {uploadingType === "google-drive" && (
                    <div
                      className="m-auto text-center video--content-font"
                      style={{ width: "70%" }}
                    >
                      <label htmlFor=""> Enter a Google Drive URL </label>
                      {/* <p className="finger-pointing-down"></p> */}

                      <div className="videoImg-content">
                        <img
                          src={`${process.env.REACT_APP_PROJECT_URL}/img/videoURL.svg`}
                          alt="videoImage"
                        />
                        <input
                          style={{
                            // cursor: "not-allowed",
                            opacity: "1",
                          }}
                          type="text"
                          // className={`voideourl--text ${
                          //   !_.isEmpty(props.user) &&
                          //   !props.user.plan_details.video_is_allowed
                          //     ? ""
                          //     : ""
                          // }`}
                          className="voideourl--text"
                          name="videourl"
                          data-tip
                          data-for="upload_video"
                          value={state.driveVideo}
                          onChange={driveFunction}
                          placeholder="Paste audio URL from Gdrive"
                          // disabled
                          onClick={() =>
                            setState({
                              activity_count: state.activity_count + 1,
                            })
                          }
                        />
                      </div>

                      {state.driveVideo && (
                        <div
                          style={{
                            display: "inline-flex",
                            gap: "10px",
                            alignItems: "center",
                            position: "relative",
                            marginTop: "1rem",
                          }}
                        >
                          {!state.isCustomDuration ? (
                            <div
                              style={{
                                cursor: "pointer",
                                color: "#49c0a0",
                              }}
                              onClick={isCustomDurationHandler}
                            >
                              Click here to select Duration.
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <TimeInput
                                  id="startTime"
                                  timeStampHandler={(secs) =>
                                    startTimeStampHandler(secs)
                                  }
                                  startTyping={startTypingTime}
                                  isValid={state.timeInputMsg}
                                />
                                to
                                <TimeInput
                                  id="endTime"
                                  timeStampHandler={(secs) =>
                                    endTimeStampHandler(secs)
                                  }
                                  startTyping={startTypingTime}
                                  isValid={state.timeInputMsg}
                                />
                                <span
                                  style={{
                                    fontWeight: "700",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleClearCustomDuration}
                                >
                                  x
                                </span>
                              </div>
                              <div
                                style={{
                                  color: "#dc3545",
                                  fontWeight: "500",
                                  marginTop: "6px",
                                  fontSize: "13px",
                                }}
                                className="text-left"
                              >
                                {state.timeInputMsg}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {/* Video Content Goes Here */}
              {state.currentActiveTab === 3 && (
                <CustomQuestions isblooms={is_bloom} />
              )}

              <div className="btn-flex100 video-section">
                {AudioExtractButtonContent()}
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                state.defaultActiveTab == "wiki" ? "active show" : ""
              }`}
              id="wiki"
              role="tabpanel"
              aria-labelledby="wiki-tab"
            >
              <div className="form-group custom-login-field">
                <div className="position-relative" style={{ height: "400px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <label
                      htmlFor="searchTopic"
                      className={classNames(
                        styles["question-generate-tab-label"],

                        "custom-login-field--label"
                      )}
                    >
                      Search Your Topic Here
                    </label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "10px",
                      }}
                    >
                      <label
                        className="custom-login-field--label"
                        style={{
                          fontSize: "13px",
                          fontWeight: "700",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {isSearch ? "Wiki Search" : "AI Gen"}
                        {/* AI /  */}
                      </label>
                      <ReactSwitch
                        onColor="#49c0a0"
                        height={20}
                        width={36}
                        // checkedIcon={false}
                        // uncheckedIcon={false}
                        checked={isSearch}
                        onChange={handleToggle}
                      />
                    </div>
                  </div>
                  {!isSearch ? (
                    <>
                      <input
                        className={classNames(
                          styles["question-generate-tab-input"],
                          styles["p-input"],
                          "custom-login-field--inputs form-control px-2 resize--none"
                        )}
                        name="searchTopic"
                        id="searchTopic"
                        onChange={onInputChangeWiki}
                        onKeyDown={handleEnterTopic}
                        // onChange={(option) =>
                        //   handleWikiTopicSelect("searchTopic", option)
                        // }
                        // onFocus={handleWikiTopicFocus}
                        // onBlur={handleWikiTopicBlur}
                        // onClick={handleWikiTopicFocus}
                        value={state.tmpselectedWikiTopic}
                        // options={state.options}
                        placeholder="Enter Topic Name and Press Enter"
                        style={{ padding: "5px" }}
                      />
                      <i
                        className="fa fa-search search-hots"
                        aria-hidden="true"
                        onClick={getContent}
                        style={{ marginTop: "-25px" }}
                      ></i>
                    </>
                  ) : (
                    <>
                      <Select
                        name="searchTopic"
                        className={classNames(
                          styles["question-generate-tab-input"]
                          // styles["border-new"]
                        )}
                        id="searchTopic"
                        onInputChange={onInputChange}
                        onChange={(option) =>
                          handleWikiTopicSelect("searchTopic", option)
                        }
                        onFocus={handleWikiTopicFocus}
                        onBlur={handleWikiTopicBlur}
                        onClick={handleWikiTopicFocus}
                        value={state.tmpselectedWikiTopic}
                        options={state.options}
                        placeholder="Enter Topic Name & Hold On"
                      />
                    </>
                  )}
                  {/* {!isSearch && (
                    <>
                      <Select
                        name="searchTopic"
                        className={classNames(
                          styles["question-generate-tab-input"]
                          // styles["border-new"]
                        )}
                        id="searchTopic"
                        onInputChange={onInputChange}
                        onChange={(option) =>
                          handleWikiTopicSelect("searchTopic", option)
                        }
                        onFocus={handleWikiTopicFocus}
                        onBlur={handleWikiTopicBlur}
                        onClick={handleWikiTopicFocus}
                        value={state.tmpselectedWikiTopic}
                        options={state.options}
                        placeholder={placeholders.searchTopic}
                      />
                    </>
                  )}{" "}
                  <div>
                    {is_bloom && (
                      <>
                        <input
                          className={classNames(
                            styles["question-generate-tab-input"],
                            styles["p-input"],
                            "custom-login-field--inputs form-control px-2 resize--none"
                          )}
                          name="searchTopic"
                          id="searchTopic"
                          onChange={onInputChange}
                          onKeyDown={handleEnterTopic}
                          // onChange={(option) =>
                          //   handleWikiTopicSelect("searchTopic", option)
                          // }
                          // onFocus={handleWikiTopicFocus}
                          // onBlur={handleWikiTopicBlur}
                          // onClick={handleWikiTopicFocus}
                          value={state.tmpselectedWikiTopic}
                          // options={state.options}
                          placeholder={placeholders.searchTopic}
                          style={{ padding: "5px" }}
                        />
                        <i
                          className="fa fa-search search-hots"
                          aria-hidden="true"
                          onClick={getContent}
                          style={{ marginTop: "-25px" }}
                        ></i>
                      </>
                    )} */}
                  <div style={{ height: "75%" }}>
                    <label
                      htmlFor="searchTopic"
                      className={classNames(
                        styles["question-generate-tab-label"],
                        styles["extract-flex"]
                      )}
                    >
                      <span
                      // style={{
                      //   color: state.webUrlContant ? "#44bfa2" : "#c6c6c6",
                      // }}
                      >
                        Extracted text preview
                      </span>
                      <span>
                        <span
                          className={styles["ext-word"]}
                          style={{
                            color:
                              state.wikiContent
                                ?.split(/\s+/)
                                .filter((word) => word.length > 0).length >
                              wordLimit
                                ? "red"
                                : "",
                          }}
                        >
                          Words:{" "}
                          {state.wikiContent
                            ? state.wikiContent
                                .split(/\s+/)
                                .filter((word) => word.length > 0).length
                            : 0}
                        </span>
                      </span>
                    </label>

                    {state.defaultActiveTab === "wiki" && (
                      <div
                        ref={(el) => (contentEditableRefs.current["wiki"] = el)} // Attach the ref to the contentEditable div
                        contentEditable={!!state.wikiContent}
                        suppressContentEditableWarning={true}
                        className={classNames(
                          styles["question-generate-tab-input"],
                          state.wikiContent
                            ? styles["border-active"]
                            : styles["border-nonactive"],
                          "custom-login-field--inputs form-control px-2 resize--none"
                        )}
                        onInput={handleInput}
                        onFocus={handleFocus} // Prevent jumping when clicking
                        onBlur={handleBlur}
                        style={{
                          minHeight: "90%",
                          maxHeight: "90%",
                          padding: "10px",
                          overflowY: "auto",
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                        }}
                      ></div>
                    )}
                  </div>
                </div>

                {state.currentActiveTab === 1 && (
                  <CustomQuestions isblooms={is_bloom} />
                )}

                <div className="btn-flex100">
                  {/* Filter Section */}
                  {/* <Filter/> */}
                  {/* {showSelectedFilter()} */}
                  <button
                    type="button"
                    className={classNames(
                      styles["question-generate-submit"],
                      "btn--submit"
                    )}
                    id="wiki-submit-button"
                    onClick={
                      props.user.credits <= 1 &&
                      !props.user.plan_details.is_new_pricing &&
                      is_bloom
                        ? redirectToPlansPage
                        : handleGenerateQuestionForWiki
                    }
                    disabled={
                      state.wikiContent
                        ? false
                        : props?.user?.plan_details?.is_new_pricing
                        ? true
                        : false
                    }
                  >
                    {props.user.credits <= 1 &&
                    !props.user.plan_details.is_new_pricing &&
                    is_bloom
                      ? "Buy Credits"
                      : "Generate Questions"}
                  </button>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                state.defaultActiveTab == "Weburl-tab" ? "active show" : ""
              }`}
              id="Weburl-tab"
              role="tabpanel"
              aria-labelledby="Weburl-tab"
            >
              <div className="form-group custom-login-field">
                <div className="position-relative" style={{ height: "400px" }}>
                  <label
                    htmlFor="searchTopic"
                    className={classNames(
                      // styles["question-generate-tab-label"],
                      // styles["green-label"]
                      // "custom-login-field--label"
                      styles["question-generate-tab-label"],
                      "custom-login-field--label"
                    )}
                  >
                    Add or Paste your URL here
                  </label>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // padding: "5px",
                    }}
                    className={classNames(
                      styles["question-generate-tab-input"],
                      styles["p-input"],
                      "custom-login-field--inputs form-control px-2 resize--none"
                    )}
                  >
                    <input
                      name="searchTopic"
                      id="searchTopic"
                      onChange={onUrlChange}
                      onKeyDown={handleEnter}
                      value={state.selectedWebURL}
                      placeholder={placeholders.webUrl}
                      // style={{ flex: 1 }}
                    />
                    <LoginIcon
                      onClick={getUrlContant}
                      // className="search-hots"
                      className={styles["searchUrl"]}
                    />
                  </div>

                  <div style={{ height: "75%" }}>
                    <label
                      htmlFor="searchTopic"
                      className={classNames(
                        styles["question-generate-tab-label"],
                        styles["extract-flex"]
                      )}
                    >
                      <span>Extracted text preview</span>
                      <span>
                        <span
                          className={styles["ext-word"]}
                          style={{
                            color:
                              state.webUrlContant
                                ?.split(/\s+/)
                                .filter((word) => word.length > 0).length >
                              wordLimit
                                ? "red"
                                : "",
                          }}
                        >
                          Words:{" "}
                          {state.webUrlContant
                            ? state.webUrlContant
                                .split(/\s+/)
                                .filter((word) => word.length > 0).length
                            : 0}
                        </span>
                      </span>
                    </label>
                    {state.defaultActiveTab === "Weburl-tab" && (
                      <div
                        ref={(el) =>
                          (contentEditableRefs.current["Weburl-tab"] = el)
                        } // Attach the ref to the contentEditable div
                        contentEditable={!!state.webUrlContant} // Editable only when content is present
                        suppressContentEditableWarning={true}
                        className={classNames(
                          styles["question-generate-tab-input"],
                          state.webUrlContant
                            ? styles["border-active"]
                            : styles["border-nonactive"],
                          "custom-login-field--inputs form-control px-2 resize--none"
                        )}
                        onInput={handleInput}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        style={{
                          minHeight: "90%",
                          maxHeight: "90%",
                          padding: "10px",
                          overflowY: "auto",
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                        }}
                      ></div>
                    )}
                  </div>
                </div>
                {state.currentActiveTab === 1 && (
                  <CustomQuestions isblooms={is_bloom} />
                )}
                <div className="btn-flex100">
                  <button
                    type="button"
                    className={classNames(
                      styles["question-generate-submit"],
                      "btn--submit"
                    )}
                    id="webUrl-submit-button"
                    onClick={
                      props.user.credits <= 1 &&
                      !props.user.plan_details.is_new_pricing &&
                      is_bloom
                        ? redirectToPlansPage
                        : handleGenerateUrl
                    }
                    disabled={
                      state.webUrlContant
                        ? false
                        : props?.user?.plan_details?.is_new_pricing
                        ? true
                        : false
                    }
                  >
                    {props.user.credits <= 1 &&
                    !props.user.plan_details.is_new_pricing &&
                    is_bloom
                      ? "Buy Credits"
                      : "Generate Questions"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GenerateQuestionStdHots;
