export const warningPrompt = {
  warning_1: {
    title: "⚠️ Unusual Login Activity Detected",
    description:
      "We've detected logins from multiple devices on your account. For security reasons, we allow up to 3 devices per week. Continued use of excessive devices may result in account restrictions.",
    email:
      "📩 An email with further details has been sent to your registered email.",
    button: "I Understand",
  },
  warning_2: {
    title: "⚠️ Repeated Unusual Login Activity",
    description:
      "Your account has exceeded the allowed device limit for 2 consecutive weeks. Continued violation for 1 more consecutive week may lead to account suspension.",
    email: "📩 Check your email for more details.",
    button: "I will take care next time",
  },
  warning_3_1: {
    title: "⚠️ Repeated Unusual Login Activity",
    description:
      "Your account has reached the allowed device limit for the 3 consecutive weeks. Continued violation will lead to account suspension.",
    email: "📩 Check your email for more details.",
    button: "Ok, Thanks for the heads up !",
  },
  warning_3_2: {
    title: "🚫 Account Suspended Due to Excessive Device Usage",
    description:
      "Your account has been suspended due to repeated violations of our device usage policy.",
    email:
      "📩 An email with reactivation steps has been sent to your registered email.",
    button: "Logging out of your account",
  },
};
