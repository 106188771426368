export const MATHS_TOPIC = [
  { value: 0, label: "Numbers and operations" },
  { value: 1, label: "Arithmetic" },
  { value: 2, label: "Fractions" },
  { value: 3, label: "Decimals" },
  { value: 4, label: "Percents" },
  { value: 5, label: "Order of operations" },
  { value: 6, label: "Exponents" },
  { value: 7, label: "Roots and radicals" },
  { value: 8, label: "Scientific notation" },
  { value: 9, label: "Algebraic expressions" },
  { value: 10, label: "Equations" },
  { value: 11, label: "Inequalities" },
  { value: 12, label: "Linear functions" },
  { value: 13, label: "Graphing" },
  { value: 14, label: "Quadratic functions" },
  { value: 15, label: "Polynomials" },
  { value: 16, label: "Factoring" },
  { value: 17, label: "Rational expressions" },
  { value: 18, label: "Exponential functions" },
  { value: 19, label: "Logarithmic functions" },
  { value: 20, label: "Trigonometry" },
  { value: 21, label: "Pythagorean theorem" },
  { value: 22, label: "Right triangle trigonometry" },
  { value: 23, label: "Trigonometric functions" },
  { value: 24, label: "Trigonometric identities" },
  { value: 25, label: "Law of sines" },
  { value: 26, label: "Law of cosines" },
  { value: 27, label: "Complex numbers" },
  { value: 28, label: "Matrices" },
  { value: 29, label: "Determinants" },
  { value: 30, label: "Vectors" },
  { value: 31, label: "Systems of equations" },
  { value: 32, label: "Conic sections" },
  { value: 33, label: "Sequences" },
  { value: 34, label: "Series" },
  { value: 35, label: "Limits" },
  { value: 36, label: "Derivatives" },
  { value: 37, label: "Chain rule" },
  { value: 38, label: "Product rule" },
  { value: 39, label: "Quotient rule" },
  { value: 40, label: "Implicit differentiation" },
  { value: 41, label: "Related rates" },
  { value: 42, label: "Applications of derivatives" },
  { value: 43, label: "Mean value theorem" },
  { value: 44, label: "Integrals" },
  { value: 45, label: "Integration by substitution" },
  { value: 46, label: "Integration by parts" },
  { value: 47, label: "Trigonometric substitutions" },
  { value: 48, label: "Partial fractions" },
  { value: 49, label: "Applications of integrals" },
  { value: 50, label: "Fundamental theorem of calculus" },
  { value: 51, label: "Polar coordinates" },
  { value: 52, label: "Parametric equations" },
  { value: 53, label: "Vector-valued functions" },
  { value: 54, label: "Three-dimensional geometry" },
  { value: 55, label: "Conic sections in polar coordinates" },
  { value: 56, label: "Partial derivatives" },
  { value: 57, label: "Gradient" },
  { value: 58, label: "Divergence" },
  { value: 59, label: "Curl" },
  { value: 60, label: "Laplacian" },
  { value: 61, label: "Taylor series" },
  { value: 62, label: "Power series" },
  { value: 63, label: "Fourier series" },
  { value: 64, label: "Differential equations" },
  { value: 65, label: "Separation of variables" },
  { value: 66, label: "Homogeneous equations" },
  { value: 67, label: "Nonhomogeneous equations" },
  { value: 68, label: "First-order equations" },
  { value: 69, label: "Higher-order equations" },
  { value: 70, label: "Series solutions" },
];

export const CHEMISTRY_TOPIC = [
  { value: 0, label: "Chemistry basics" },
  { value: 1, label: "Chemical reactions and stoichiometry" },
  { value: 2, label: "Acid-base chemistry" },
  { value: 3, label: "Solutions and solubility" },
  { value: 4, label: "Chemical equilibrium" },
  { value: 5, label: "Thermochemistry" },
  { value: 6, label: "Electrochemistry" },
  { value: 7, label: "Organic chemistry" },
  { value: 8, label: "Wave-particle duality" },
  { value: 9, label: "Photoelectric effect" },
  { value: 10, label: "Spectroscopy and energy levels" },
  { value: 11, label: "Uncertainty principle" },
  { value: 12, label: "Radioactivity and nuclear reactions" },
  { value: 13, label: "Doppler effect" },
  { value: 14, label: "Oceanography and marine science" },
  { value: 15, label: "Environmental science" },
  { value: 16, label: "Atomic structure" },
  { value: 17, label: "Periodic table and periodicity" },
  { value: 18, label: "Chemical bonding" },
  { value: 19, label: "Chemical reactions" },
  { value: 20, label: "Stoichiometry" },
  { value: 21, label: "Gas laws" },
  { value: 22, label: "Solutions and solubility" },
  { value: 23, label: "Acids and bases" },
  { value: 24, label: "Redox reactions" },
  { value: 26, label: "Electrochemistry" },
  { value: 27, label: "Organic chemistry" },
  { value: 28, label: "Alkanes, alkenes, and alkynes" },
  { value: 29, label: "Functional groups" },
  { value: 30, label: "Isomerism" },
  { value: 31, label: "Alcohols and ethers" },
  { value: 32, label: "Carboxylic acids and esters" },
  { value: 33, label: "Amines and amides" },
  { value: 34, label: "Polymers" },
  { value: 35, label: "Biochemistry" },
  { value: 36, label: "Proteins and enzymes" },
  { value: 37, label: "Carbohydrates" },
  { value: 38, label: "Lipids" },
  { value: 39, label: "Nucleic acids" },
  { value: 40, label: "Chemical kinetics" },
  { value: 41, label: "Equilibrium" },
  { value: 42, label: "Le Chatelier's principle" },
  { value: 43, label: "Acid-base equilibria" },
  { value: 44, label: "Solubility equilibria" },
  { value: 45, label: "Thermodynamics" },
  { value: 46, label: "Laws of thermodynamics" },
  { value: 47, label: "Entropy and free energy" },
  { value: 48, label: "Hess's law" },
  { value: 49, label: "Calorimetry" },
  { value: 50, label: "Kinetic theory of gases" },
  { value: 51, label: "Ideal gas law" },
  { value: 52, label: "Van der Waals equation" },
  { value: 53, label: "Henry's law" },
  { value: 54, label: "Boyle's law" },
  { value: 55, label: "Charles's law" },
  { value: 56, label: "Dalton's law of partial pressures" },
  { value: 57, label: "Combined gas law" },
  { value: 58, label: "Dalton's atomic theory" },
  { value: 59, label: "Law of definite proportions" },
  { value: 60, label: "Law of multiple proportions" },
  { value: 61, label: "Mole concept" },
  { value: 62, label: "Avogadro's law" },
  { value: 63, label: "Empirical and molecular formulas" },
  { value: 64, label: "Balancing chemical equations" },
  { value: 65, label: "Stoichiometric calculations" },
  { value: 66, label: "Limiting reactants" },
  { value: 67, label: "Percent yield" },
  { value: 68, label: "Solutions and concentration units" },
  { value: 69, label: "Molarity" },
  { value: 70, label: "Molality" },
  { value: 71, label: "Parts per million and parts per billion" },
  { value: 72, label: "Solubility product constant" },
  { value: 73, label: "Acid dissociation constant" },
  { value: 74, label: "Base dissociation constant" },
  { value: 75, label: "Henderson-Hasselbalch equation" },
  { value: 76, label: "Titration curves" },
  { value: 77, label: "Buffers" },
  { value: 78, label: "Oxidation states" },
  { value: 79, label: "Balancing redox equations" },
  { value: 80, label: "Electrochemical cells" },
  { value: 81, label: "Nernst equation" },
  { value: 82, label: "Faraday's laws of electrolysis" },
  { value: 83, label: "Organic functional groups" },
  { value: 84, label: "Alkanes, alkenes, and alkynes reactions" },
  { value: 85, label: "SN1 and SN2 reactions" },
  { value: 86, label: "E1 and E2 reactions" },
  { value: 87, label: "Fischer projections" },
  { value: 88, label: "Organic spectroscopy" },
];

export const PHYSICS_TOPIC = [
  { value: 0, label: "Kinematics" },
  { value: 1, label: "Newton's laws of motion" },
  { value: 2, label: "Forces and friction" },
  { value: 3, label: "Work, energy, and power" },
  { value: 4, label: "Conservation of energy" },
  { value: 5, label: "Linear momentum" },
  { value: 6, label: "Circular motion" },
  { value: 7, label: "Gravity and orbits" },
  { value: 8, label: "Projectile motion" },
  { value: 9, label: "Torque and rotational motion" },
  { value: 10, label: "Simple harmonic motion" },
  { value: 11, label: "Waves and wave properties" },
  { value: 12, label: "Sound and sound waves" },
  { value: 13, label: "Light and optics" },
  { value: 14, label: "Reflection and refraction" },
  { value: 15, label: "Mirrors and lenses" },
  { value: 16, label: "Electromagnetic waves" },
  { value: 17, label: "Electric charge and electric field" },
  { value: 18, label: "Coulomb's law" },
  { value: 19, label: "Electric potential and electric potential energy" },
  { value: 20, label: "Capacitance and capacitors" },
  { value: 21, label: "Electric current and circuits" },
  { value: 22, label: "Ohm's law" },
  { value: 23, label: "Resistors and resistivity" },
  { value: 24, label: "Kirchhoff's laws" },
  { value: 25, label: "Magnetic fields and forces" },
  { value: 26, label: "Electromagnetic induction" },
  { value: 27, label: "Transformers and generators" },
  { value: 28, label: "Atomic structure" },
  { value: 29, label: "Radioactivity and nuclear reactions" },
  { value: 30, label: "Quantum mechanics" },
  { value: 31, label: "Particle physics" },
  { value: 32, label: "Heat and temperature" },
  { value: 33, label: "Thermal energy and heat transfer" },
  { value: 34, label: "Specific heat and calorimetry" },
  { value: 35, label: "Gas laws" },
  { value: 36, label: "Ideal gas law" },
  { value: 37, label: "Kinetic theory of gases" },
  { value: 38, label: "Thermodynamics" },
  { value: 39, label: "Laws of thermodynamics" },
  { value: 40, label: "Entropy and free energy" },
  { value: 41, label: "Phase changes and phase diagrams" },
  { value: 42, label: "Properties of solids, liquids, and gases" },
  { value: 43, label: "Density and buoyancy" },
  { value: 44, label: "Pressure and Pascal's principle" },
  { value: 45, label: "Fluids and fluid mechanics" },
  { value: 46, label: "Archimedes' principle" },
  { value: 47, label: "Viscosity and Bernoulli's principle" },
];

export const APTITUDE_TOPIC = [
  { value: 0, label: "Number Series" },
  { value: 1, label: "Coding and Number Series" },
  { value: 2, label: "Letter and Symbol Series" },
  { value: 3, label: "Logical Sequence of Words" },
  { value: 4, label: "Analogy and Classification Pattern" },
  { value: 5, label: "Cube and Cuboid" },
  { value: 6, label: "Cube" },
  { value: 7, label: "Dice" },
  { value: 8, label: "Classic Reasoning" },
  { value: 9, label: "Directional Senses" },
  { value: 10, label: "Blood Relations" },
  { value: 11, label: "Odd Man Out" },
  { value: 12, label: "Syllogism" },
  { value: 13, label: "Arrangements" },
  { value: 14, label: "Seating Arrangements" },
  { value: 15, label: "Coding Deductive Logic" },
  { value: 16, label: "Objective Reasoning" },
  { value: 17, label: "Selection Decision Tables" },
  { value: 18, label: "Attention to Details" },
  { value: 19, label: "Inferred Meaning" },
  { value: 20, label: "Cryptarithmetic" },
  { value: 21, label: "Deductive reasoning" },
  { value: 22, label: "Inductive reasoning" },
  { value: 23, label: "Abductive reasoning" },
  { value: 24, label: "Analogical reasoning" },
  { value: 25, label: "Syllogisms" },
  { value: 26, label: "Conditional reasoning" },
  { value: 27, label: "Propositional logic" },
  { value: 28, label: "Predicate logic" },
  { value: 29, label: "Truth tables" },
  { value: 30, label: "Logical fallacies" },
  { value: 31, label: "Venn diagrams" },
  { value: 32, label: "Categorical syllogisms" },
  { value: 33, label: "Quantifiers" },
  { value: 34, label: "Argument analysis" },
  { value: 35, label: "Inference making" },
  { value: 36, label: "Decision making" },
  { value: 37, label: "Game theory" },
  { value: 38, label: "Modal logic" },
  { value: 39, label: "Counterfactual reasoning" },
  { value: 40, label: "Non-monotonic reasoning" },
  { value: 41, label: "Fuzzy logic" },
  { value: 42, label: "Intuitionistic logic" },
  { value: 43, label: "Epistemic logic" },
  { value: 44, label: "Deontic logic" },
  { value: 45, label: "Temporal logic" },
];

// Formulas
export const PHYSICS_FORMULA = [
  { value: 0, label: "Newton's Second Law of Motion: F = ma" },
  { value: 1, label: "Kinetic Energy: KE = 0.5mv^2" },
  { value: 2, label: "Gravitational Potential Energy: PE = mgh" },
  { value: 3, label: "Work-Energy Theorem: W = ∆KE = Fd" },
  { value: 4, label: "Power: P = W/t" },
  {
    value: 5,
    label: "Law of Conservation of Energy: KEi + PEi + Wi = KEf + PEf + Wf",
  },
  { value: 6, label: "Momentum: p = mv" },
  { value: 7, label: "Impulse: J = ∆p = F∆t" },
  { value: 8, label: "Law of Conservation of Momentum: pi = pf" },
  { value: 9, label: "Circular Motion: Fc = mv^2/r" },
  { value: 10, label: "Centripetal Acceleration: ac = v^2/r" },
  { value: 11, label: "Torque: τ = Frsinθ" },
  { value: 12, label: "Angular Momentum: L = Iω" },
  { value: 13, label: "Work done by a Torque: W = τθ" },
  { value: 14, label: "Simple Harmonic Motion: x = A cos(ωt + φ)" },
  { value: 15, label: "Periodic Time: T = 2π/ω" },
  { value: 16, label: "Hooke's Law: F = -kx" },
  { value: 17, label: "Density: ρ = m/V" },
  { value: 18, label: "Pressure: P = F/A" },
  { value: 19, label: "Pascal's Principle: P1 = P2" },
  { value: 20, label: "Archimedes' Principle: Fb = ρVg" },
  { value: 21, label: "Gas Laws: PV = nRT" },
  { value: 22, label: "Ideal Gas Law: PV = NkBT" },
  { value: 23, label: "Boyle's Law: P1V1 = P2V2" },
  { value: 24, label: "Charles' Law: V1/T1 = V2/T2" },
  { value: 25, label: "Avogadro's Law: V1/n1 = V2/n2" },
  { value: 26, label: "Specific Heat: Q = mc∆T" },
  { value: 27, label: "Latent Heat: Q = mL" },
  { value: 28, label: "First Law of Thermodynamics: ∆U = Q - W" },
  { value: 29, label: "Second Law of Thermodynamics: ∆S ≥ Q/T" },
  { value: 30, label: "Entropy: S = k ln(W)" },
  { value: 31, label: "Coulomb's Law: F = kq1q2/r^2" },
  { value: 32, label: "Electric Field: E = F/q" },
  { value: 33, label: "Electric Potential Energy: U = kq1q2/r" },
  { value: 34, label: "Electric Potential: V = U/q" },
  { value: 35, label: "Capacitance: C = Q/V" },
  { value: 36, label: "Electric Current: I = Q/t" },
  { value: 37, label: "Resistance: R = V/I" },
  { value: 38, label: "Ohm's Law: V = IR" },
  { value: 39, label: "Kirchhoff's Laws: ∑V = 0, ∑I = 0" },
  { value: 40, label: "Magnetic Field: B = F/qv" },
  { value: 41, label: "Magnetic Force: F = qvBsinθ" },
  { value: 42, label: "Magnetic Flux: Φ = BAcosθ" },
  { value: 43, label: "Faraday's Law: ε = -dΦ/dt" },
  { value: 44, label: "Inductance: L = Φ/I" },
  { value: 45, label: "AC Circuits: V = Vm sin(ωt + φ)" },
  { value: 46, label: "Resonance Frequency: f = 1/2π√(LC)" },
  { value: 47, label: "Reflection: θi = θr" },
  { value: 48, label: "Refraction: n1sinθ1 = n2sinθ2" },
  { value: 49, label: "Diffraction: λ = d sinθ" },
];

export const MATHS_FORMULA = [
  { value: 0, label: "Pythagorean Theorem: a² + b² = c²" },
  { value: 1, label: "Quadratic Formula: x = (-b ± √(b²-4ac))/2a" },
  { value: 2, label: "Distance Formula: d = √((x2 - x1)² + (y2 - y1)²)" },
  { value: 3, label: "Slope of a Line: m = (y2 - y1)/(x2 - x1)" },
  { value: 4, label: "Midpoint Formula: ((x1 + x2)/2 , (y1 + y2)/2)" },
  { value: 5, label: "Area of a Triangle: A = 1/2bh" },
  { value: 6, label: "Volume of a Sphere: V = 4/3πr³" },
  { value: 7, label: "Area of a Circle: A = πr²" },
  { value: 8, label: "Surface Area of a Sphere: SA = 4πr²" },
  { value: 9, label: "Sin Rule: a/sin(A) = b/sin(B) = c/sin(C)" },
  { value: 10, label: "Cos Rule: a² = b² + c² - 2bc cos(A)" },
  {
    value: 11,
    label:
      "Logarithmic identities: log(ab) = log(a) + log(b), log(a/b) = log(a) - log(b), log(a^n) = nlog(a)",
  },
  {
    value: 12,
    label:
      "Probability of an event: P(E) = number of favorable outcomes/total number of outcomes",
  },
  { value: 13, label: "Perimeter of a rectangle: P = 2(l + w)" },
  { value: 14, label: "Area of a rectangle: A = lw" },
  { value: 15, label: "Volume of a rectangular prism: V = lwh" },
  {
    value: 16,
    label: "Surface Area of a rectangular prism: SA = 2lw + 2lh + 2wh",
  },
  { value: 17, label: "Arithmetic sequence: an = a1 + (n-1)d" },
  { value: 18, label: "Geometric sequence: an = a1(r^(n-1))" },
  { value: 19, label: "Factorial: n! = n(n-1)(n-2)...(3)(2)(1)" },
  { value: 20, label: "Combinations: nCk = n!/[(n-k)!k!]" },
  {
    value: 21,
    label: "Binomial Theorem: (a + b)^n = Σ (n choose k) a^(n-k) b^k",
  },
  { value: 22, label: "Law of Sines: sin(A)/a = sin(B)/b = sin(C)/c" },
  { value: 23, label: "Law of Cosines: a² = b² + c² - 2bc cos(A)" },
  { value: 24, label: "Quadratic Regression Formula: y = a + bx + cx²" },
  {
    value: 25,
    label:
      "Trigonometric Identities: sin²(x) + cos²(x) = 1, tan(x) = sin(x)/cos(x), sin(-x) = -sin(x), cos(-x) = cos(x)",
  },
  { value: 26, label: "Sum of an infinite geometric series: S = a/(1 - r)" },
  { value: 27, label: "Sum of a finite arithmetic series: S = (n/2)(a1 + an)" },
  {
    value: 28,
    label: "Sum of a finite geometric series: S = (a1(1 - r^n))/(1 - r)",
  },
  {
    value: 29,
    label: "Logarithmic differentiation: d/dx(log(f(x))) = f'(x)/f(x)",
  },
  {
    value: 30,
    label: "Integration by substitution: ∫f(g(x))g'(x)dx = ∫f(u)du",
  },
  { value: 31, label: "Integration by parts: ∫u dv = uv - ∫v du" },
  {
    value: 32,
    label:
      "Limits: lim(x → a) f(x) = L if and only if for every ε > 0, there exists δ > 0 such that if 0 < |x - a| < δ, then |f(x) - L| < ε",
  },
  {
    value: 33,
    label: "Derivative of a function: f'(x) = lim(h → 0) (f(x + h) - f(x))/h",
  },
];

export const CHEMISTRY_FORMULA = [
  { value: 0, label: "Avogadro's number: NA = 6.022 x 10^23 mol^-1" },
  { value: 1, label: "Molar mass: M = m/n" },
  { value: 2, label: "Ideal gas law: PV = nRT" },
  { value: 3, label: "Mole fraction: X = n1/n(total)" },
  {
    value: 4,
    label:
      "Mass percent composition: % composition = (mass of element/mass of compound) x 100%",
  },
  { value: 5, label: "Molarity: M = n/V" },
  { value: 6, label: "Molality: m = n/mass of solvent (in kg)" },
  { value: 7, label: "Boiling point elevation: ΔTb = Kb x m" },
  { value: 8, label: "Freezing point depression: ΔTf = Kf x m" },
  { value: 9, label: "Raoult's law: Psolution = Xsolvent x Psolvent" },
  { value: 10, label: "Henry's law: Pgas = Kh x concentration of gas" },
  { value: 11, label: "Ideal solution: ΔHmix = 0" },
  { value: 12, label: "Non-ideal solution: ΔHmix ≠ 0" },
  { value: 13, label: "Reaction rate: rate = k[A]^m[B]^n" },
  { value: 14, label: "Equilibrium constant: Kc = [C]^c[D]^d/[A]^a[B]^b" },
  { value: 15, label: "Gibbs free energy: ΔG = ΔH - TΔS" },
  { value: 16, label: "Standard free energy change: ΔG° = -RT lnK" },
  {
    value: 17,
    label:
      "Le Chatelier's principle: a system at equilibrium will respond to a stress in a way that opposes the stress",
  },
  { value: 18, label: "Acid dissociation constant: Ka = [H3O+][A-]/[HA]" },
  { value: 19, label: "Base dissociation constant: Kb = [BH+][OH-]/[B]" },
  { value: 20, label: "pH: pH = -log[H3O+]" },
  { value: 21, label: "pOH: pOH = -log[OH-]" },
  { value: 22, label: "pKa: pKa = -log(Ka)" },
  { value: 23, label: "pKb: pKb = -log(Kb)" },
  {
    value: 24,
    label: "Buffer capacity: β = (moles of acid or base added)/(change in pH)",
  },
  {
    value: 25,
    label: "Henderson-Hasselbalch equation: pH = pKa + log([A-]/[HA])",
  },
  { value: 26, label: "Solubility product constant: Ksp = [A+]^m[B-]^n" },
  {
    value: 27,
    label:
      "Common ion effect: the solubility of a salt is decreased by the presence of a common ion",
  },
  {
    value: 28,
    label:
      "Acid-base indicators: substances that change color depending on the pH of the solution",
  },
  {
    value: 29,
    label:
      "Oxidation number: the charge an atom would have if electrons were transferred completely",
  },
  {
    value: 30,
    label:
      "Oxidation-reduction reaction: a reaction in which electrons are transferred from one species to another",
  },
  {
    value: 31,
    label:
      "Balancing redox reactions: use half-reactions and balance the number of electrons transferred",
  },
  { value: 32, label: "Faraday's law: ΔEcell = Ecell cathode - Ecell anode" },
  { value: 33, label: "Nernst equation: Ecell = E°cell - (RT/nF) lnQ" },
  { value: 34, label: "Standard reduction potential: E°red = -ΔG°/nF" },
  {
    value: 35,
    label:
      "Acid-base titration: a method of determining the concentration of an acid or base using a known concentration of the opposite species",
  },
  {
    value: 36,
    label:
      "Gravimetric analysis: a method of determining the amount of a substance by measuring its mass",
  },
  {
    value: 37,
    label:
      "Spectroscopy: a method of identifying substances by their interaction with electromagnetic radiation",
  },
  {
    value: 38,
    label:
      "Infrared spectroscopy: a method of identifying functional groups in organic molecules",
  },
  {
    value: 39,
    label:
      "Nuclear magnetic resonance spectroscopy: a method of determining the structure of organic molecules",
  },
  {
    value: 40,
    label:
      "Chromatography: a method of separating mixtures based on differences in solubility and/or polarity",
  },
  {
    value: 41,
    label:
      "Distillation: a method of separating mixtures based on differences in boiling",
  },
];

export const Subject_Data = {
  IX: {
    science: [
      { value: "Nature of matter", label: "Nature of matter" },
      {
        value: "Particle nature and their basic units",
        label: "Particle nature and their basic units",
      },
      { value: "Structure of atoms", label: "Structure of atoms" },
      {
        value: "Cell - Basic Unit of life",
        label: "Cell - Basic Unit of life",
      },
      {
        value: "Tissues, Organs, Organ System, Organism",
        label: "Tissues, Organs, Organ System, Organism",
      },
      { value: "Motion", label: "Motion" },
      { value: "Force and Newton’s laws", label: "Force and Newton’s laws" },
      { value: "Gravitation", label: "Gravitation" },
      { value: "Floatation", label: "Floatation" },
      { value: "Work, Energy and Power", label: "Work, Energy and Power" },
      { value: "Sound", label: "Sound" },
      { value: "Food Production", label: "Food Production" },
    ],
    maths: [
      { value: "REAL NUMBERS", label: "REAL NUMBERS" },
      { value: "POLYNOMIALS", label: "POLYNOMIALS" },
      {
        value: "LINEAR EQUATIONS IN TWO VARIABLES",
        label: "LINEAR EQUATIONS IN TWO VARIABLES",
      },
      { value: "COORDINATE GEOMETRY", label: "COORDINATE GEOMETRY" },
      {
        value: "INTRODUCTION TO EUCLID'S GEOMETRY",
        label: "INTRODUCTION TO EUCLID'S GEOMETRY",
      },
      { value: "LINES AND ANGLES", label: "LINES AND ANGLES" },
      { value: "TRIANGLES", label: "TRIANGLES" },
      { value: "QUADRILATERALS", label: "QUADRILATERALS" },
      { value: "CIRCLES", label: "CIRCLES" },
      { value: "AREAS", label: "AREAS" },
      {
        value: "SURFACE AREAS AND VOLUMES",
        label: "SURFACE AREAS AND VOLUMES",
      },
      { value: "STATISTICS", label: "STATISTICS" },
    ],
  },
  X: {
    science: [
      { value: "Chemical reactions", label: "Chemical reactions" },
      { value: "Acids, bases and salts", label: "Acids, bases and salts" },
      { value: "Metals and nonmetals", label: "Metals and nonmetals" },
      { value: "Carbon compounds", label: "Carbon compounds" },
      { value: "Life processes", label: "Life processes" },
      {
        value: "Control and co-ordination in animals and plants",
        label: "Control and co-ordination in animals and plants",
      },
      { value: "Reproduction", label: "Reproduction" },
      { value: "Heredity and Evolution", label: "Heredity and Evolution" },
      { value: "Natural Phenomena", label: "Natural Phenomena" },
      { value: "Electric current", label: "Electric current" },
      {
        value: "Magnetic effects of current",
        label: "Magnetic effects of current",
      },
      { value: "Our environment", label: "Our environment" },
    ],
    maths: [
      { value: "REAL NUMBER", label: "REAL NUMBER" },
      { value: "POLYNOMIALS", label: "POLYNOMIALS" },
      {
        value: "PAIR OF LINEAR EQUATIONS IN TWO VARIABLES",
        label: "PAIR OF LINEAR EQUATIONS IN TWO VARIABLES",
      },
      { value: "QUADRATIC EQUATIONS", label: "QUADRATIC EQUATIONS" },
      { value: "ARITHMETIC PROGRESSIONS", label: "ARITHMETIC PROGRESSIONS" },
      { value: "Coordinate Geometry", label: "Coordinate Geometry" },
      { value: "TRIANGLES", label: "TRIANGLES" },
      { value: "CIRCLES", label: "CIRCLES" },
      {
        value: "INTRODUCTION TO TRIGONOMETRY",
        label: "INTRODUCTION TO TRIGONOMETRY",
      },
      { value: "TRIGONOMETRIC IDENTITIES", label: "TRIGONOMETRIC IDENTITIES" },
      { value: "HEIGHTS AND DISTANCES", label: "HEIGHTS AND DISTANCES" },
      { value: "AREAS RELATED TO CIRCLES", label: "AREAS RELATED TO CIRCLES" },
      {
        value: "SURFACE AREAS AND VOLUMES",
        label: "SURFACE AREAS AND VOLUMES",
      },
      { value: "STATISTICS", label: "STATISTICS" },
      { value: "PROBABILITY", label: "PROBABILITY" },
    ],
  },
  XI: {
    physics: [
      { value: "Units and Measurements", label: "Units and Measurements" },
      {
        value: "Motion in a Straight Line",
        label: "Motion in a Straight Line",
      },
      { value: "Motion in a Plane", label: "Motion in a Plane" },
      { value: "Laws of Motion", label: "Laws of Motion" },
      { value: "Work, Energy and Power", label: "Work, Energy and Power" },
      {
        value: "System of Particles and Rotational Motion",
        label: "System of Particles and Rotational Motion",
      },
      { value: "Gravitation", label: "Gravitation" },
      {
        value: "Mechanical Properties of Solids",
        label: "Mechanical Properties of Solids",
      },
      {
        value: "Mechanical Properties of Fluids",
        label: "Mechanical Properties of Fluids",
      },
      {
        value: "Thermal Properties of Matter",
        label: "Thermal Properties of Matter",
      },
      { value: "Thermodynamics", label: "Thermodynamics" },
      { value: "Kinetic Theory", label: "Kinetic Theory" },
      { value: "Oscillations", label: "Oscillations" },
      { value: "Waves", label: "Waves" },
    ],
    biology: [
      { value: "The Living World", label: "The Living World" },
      {
        value: "Biological Classification",
        label: "Biological Classification",
      },
      { value: "Plant Kingdom", label: "Plant Kingdom" },
      { value: "Animal Kingdom", label: "Animal Kingdom" },
      {
        value: "Morphology of Flowering Plants",
        label: "Morphology of Flowering Plants",
      },
      {
        value: "Anatomy of Flowering Plants",
        label: "Anatomy of Flowering Plants",
      },
      {
        value: "Structural Organisation in Animals",
        label: "Structural Organisation in Animals",
      },
      { value: "Cell - The Unit of Life", label: "Cell - The Unit of Life" },
      { value: "Biomolecules", label: "Biomolecules" },
      {
        value: "Cell Cycle and Cell Division",
        label: "Cell Cycle and Cell Division",
      },
      {
        value: "Photosynthesis in Higher Plants",
        label: "Photosynthesis in Higher Plants",
      },
      { value: "Respiration in Plants", label: "Respiration in Plants" },
      {
        value: "Plant Growth and Development",
        label: "Plant Growth and Development",
      },
      {
        value: "Breathing and Exchange of Gases",
        label: "Breathing and Exchange of Gases",
      },
      {
        value: "Body Fluids and Circulation",
        label: "Body Fluids and Circulation",
      },
      {
        value: "Excretory Products and their Elimination",
        label: "Excretory Products and their Elimination",
      },
      { value: "Locomotion and Movement", label: "Locomotion and Movement" },
      {
        value: "Neural Control and Coordination",
        label: "Neural Control and Coordination",
      },
      {
        value: "Chemical Coordination and Integration",
        label: "Chemical Coordination and Integration",
      },
    ],
    chemistry: [
      {
        value: "Some Basic Concepts of Chemistry",
        label: "Some Basic Concepts of Chemistry",
      },
      { value: "Structure of Atom", label: "Structure of Atom" },
      {
        value: "Classification of Elements and Periodicity in Properties",
        label: "Classification of Elements and Periodicity in Properties",
      },
      {
        value: "Chemical Bonding and Molecular Structure",
        label: "Chemical Bonding and Molecular Structure",
      },
      { value: "Chemical Thermodynamics", label: "Chemical Thermodynamics" },
      { value: "Equilibrium", label: "Equilibrium" },
      { value: "Redox Reactions", label: "Redox Reactions" },
      {
        value: "Organic Chemistry - Some Basic Principles and Techniques",
        label: "Organic Chemistry - Some Basic Principles and Techniques",
      },
      { value: "Hydrocarbons", label: "Hydrocarbons" },
    ],
    maths: [
      { value: "Sets", label: "Sets" },
      { value: "Relations & Functions", label: "Relations & Functions" },
      { value: "Trigonometric Functions", label: "Trigonometric Functions" },
      {
        value: "Complex Numbers and Quadratic Equations",
        label: "Complex Numbers and Quadratic Equations",
      },
      { value: "Linear Inequalities", label: "Linear Inequalities" },
      {
        value: "Permutations and Combinations",
        label: "Permutations and Combinations",
      },
      { value: "Binomial Theorem", label: "Binomial Theorem" },
      { value: "Sequence and Series", label: "Sequence and Series" },
      { value: "Straight Lines", label: "Straight Lines" },
      { value: "Conic Sections", label: "Conic Sections" },
      {
        value: "Introduction to Three-dimensional Geometry",
        label: "Introduction to Three-dimensional Geometry",
      },
      { value: "Limits and Derivatives", label: "Limits and Derivatives" },
      { value: "Statistics", label: "Statistics" },
      { value: "Probability", label: "Probability" },
    ],
    "applied maths": [
      { value: "Numbers & Quantification", label: "Numbers & Quantification" },
      { value: "Numerical Applications", label: "Numerical Applications" },
      { value: "Sets", label: "Sets" },
      { value: "Relations", label: "Relations" },
      { value: "Sequences and Series", label: "Sequences and Series" },
      {
        value: "Permutations and Combinations",
        label: "Permutations and Combinations",
      },
      { value: "Mathematical Reasoning", label: "Mathematical Reasoning" },
      { value: "Calculus", label: "Calculus" },
      { value: "Probability", label: "Probability" },
      { value: "Descriptive Statistics", label: "Descriptive Statistics" },
      { value: "Financial Mathematics", label: "Financial Mathematics" },
      { value: "Coordinate Geometry", label: "Coordinate Geometry" },
    ],
  },
  XII: {
    physics: [
      {
        value: "Electric Charges and Fields",
        label: "Electric Charges and Fields",
      },
      {
        value: "Electrostatic Potential and Capacitance",
        label: "Electrostatic Potential and Capacitance",
      },
      { value: "Current Electricity", label: "Current Electricity" },
      {
        value: "Moving Charges and Magnetism",
        label: "Moving Charges and Magnetism",
      },
      { value: "Magnetism and Matter", label: "Magnetism and Matter" },
      {
        value: "Electromagnetic Induction",
        label: "Electromagnetic Induction",
      },
      { value: "Alternating Current", label: "Alternating Current" },
      { value: "Electromagnetic Waves", label: "Electromagnetic Waves" },
      {
        value: "Ray Optics and Optical Instruments",
        label: "Ray Optics and Optical Instruments",
      },
      { value: "Wave Optics", label: "Wave Optics" },
      {
        value: "Dual Nature of Radiation and Matter",
        label: "Dual Nature of Radiation and Matter",
      },
      { value: "Atoms", label: "Atoms" },
      { value: "Nuclei", label: "Nuclei" },
      {
        value: "Semiconductor Electronics",
        label: "Semiconductor Electronics",
      },
    ],
    biology: [
      {
        value: "Sexual Reproduction in Flowering Plants",
        label: "Sexual Reproduction in Flowering Plants",
      },
      { value: "Human Reproduction", label: "Human Reproduction" },
      { value: "Reproductive Health", label: "Reproductive Health" },
      {
        value: "Principles of Inheritance and Variation",
        label: "Principles of Inheritance and Variation",
      },
      {
        value: "Molecular Basis of Inheritance",
        label: "Molecular Basis of Inheritance",
      },
      { value: "Evolution", label: "Evolution" },
      {
        value: "Human Health and Diseases",
        label: "Human Health and Diseases",
      },
      {
        value: "Microbes in Human Welfare",
        label: "Microbes in Human Welfare",
      },
      {
        value: "Biotechnology - Principles and Processes",
        label: "Biotechnology - Principles and Processes",
      },
      {
        value: "Biotechnology and its Applications",
        label: "Biotechnology and its Applications",
      },
      {
        value: "Organisms and Populations",
        label: "Organisms and Populations",
      },
      { value: "Ecosystem", label: "Ecosystem" },
      {
        value: "Biodiversity and its Conservation",
        label: "Biodiversity and its Conservation",
      },
    ],
    chemistry: [
      { value: "Solutions", label: "Solutions" },
      { value: "Electrochemistry", label: "Electrochemistry" },
      { value: "Chemical Kinetics", label: "Chemical Kinetics" },
      { value: "d and f Block Elements", label: "d and f Block Elements" },
      { value: "Coordination Compounds", label: "Coordination Compounds" },
      {
        value: "Haloalkanes and Haloarenes",
        label: "Haloalkanes and Haloarenes",
      },
      {
        value: "Alcohols, Phenols and Ethers",
        label: "Alcohols, Phenols and Ethers",
      },
      {
        value: "Aldehydes, Ketones and Carboxylic Acids",
        label: "Aldehydes, Ketones and Carboxylic Acids",
      },
      { value: "Amines", label: "Amines" },
      { value: "Biomolecules", label: "Biomolecules" },
    ],
    maths: [
      { value: "Relations and Functions", label: "Relations and Functions" },
      {
        value: "Inverse Trigonometric Functions",
        label: "Inverse Trigonometric Functions",
      },
      { value: "Matrices", label: "Matrices" },
      { value: "Determinants", label: "Determinants" },
      {
        value: "Continuity and Differentiability",
        label: "Continuity and Differentiability",
      },
      {
        value: "Applications of Derivatives",
        label: "Applications of Derivatives",
      },
      { value: "Integrals", label: "Integrals" },
      {
        value: "Applications of the Integrals",
        label: "Applications of the Integrals",
      },
      { value: "Differential Equations", label: "Differential Equations" },
      { value: "Vectors", label: "Vectors" },
      {
        value: "Three-Dimensional Geometry",
        label: "Three-Dimensional Geometry",
      },
      { value: "Linear Programming", label: "Linear Programming" },
      { value: "Probability", label: "Probability" },
    ],
    "applied maths": [
      {
        value: "Numbers, Quantification and Numerical Applications",
        label: "Numbers, Quantification and Numerical Applications",
      },
      { value: "Algebra", label: "Algebra" },
      { value: "Calculus", label: "Calculus" },
      {
        value: "Probability Distributions",
        label: "Probability Distributions",
      },
      { value: "Inferential Statistics", label: "Inferential Statistics" },
      {
        value: "Index Numbers and Time Based Data",
        label: "Index Numbers and Time Based Data",
      },
      { value: "Financial Mathematics", label: "Financial Mathematics" },
      { value: "Linear Programming", label: "Linear Programming" },
    ],
  },
};
